import React, { useEffect, useState } from 'react'
import { inputValidationCheck } from '../../../validators/inputValidators/inputValidators'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { getInputLabel } from '../utils/getInputLabel'
import { buildControlsExt, integerInput } from '../../inputs/controls'
import { setDefaultValue } from '../utils/setDefault'
import {
    modifyActionParameter,
    setActionInvalid,
    setActionValid,
} from '../../../store/actions/actionsActions'
import { numberInputErrors } from '../../../utils/validationErrors/numberInputErrors'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { isEmpty } from '../utils/isEmpty'
import { useInitialEffect } from '../../../hooks/useInitialEffect'
import { useEffectAfterMount } from '../../../hooks/useEffectAfterMount'
import { IDurationProps } from '../../models/Duration/types'
import { useDebouncedValue } from '../../../hooks/useActions/useDebouncedValue'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { selectInvalidActionByErrorKey } from '../selectors/invalidActionsSelectors'

export const DurationACTN = (props: IDurationProps) => {
    const { data = {}, dataSchema, name, wrapper, dataPath } = props
    const dispatch = useAppDispatch()

    const [path] = useState([...(dataPath || []), name])

    const {
        is_required: isRequired,
        name: inputLabel,
        type,
        description: tooltipText,
        format,
        default: defaultValue,
    } = dataSchema

    const validationCheck = inputValidationCheck(type, dataSchema)
    const [
        inputState,
        setInputState,
        touched,
        setTouched,
        validationResult,
        isInputStateValid,
    ] = useFormValidation(data, { [name]: validationCheck })

    const debouncedInputState = useDebouncedValue(inputState, 500)
    const isActionFieldInvalid = !!useTypedSelector(
        selectInvalidActionByErrorKey(path)
    )
    const isDataEmpty = isEmpty(data, name)
    const errMsg = numberInputErrors(inputState?.[name], dataSchema)
    const label = getInputLabel(name, inputLabel)
    const className = !wrapper ? 'w-100' : ''

    const DurationInput = buildControlsExt(
        [
            integerInput({
                name,
                validationType: 'integer',
                tooltipText,
                placeholder: defaultValue || `${format}...`,
                label,
                className,
                isColumn: true,
                errMsg,
                isRequired,
            }),
        ],
        inputState,
        setInputState,
        '',
        touched,
        setTouched,
        validationResult
    )

    useInitialEffect(() => {
        if (isDataEmpty) {
            if (defaultValue) {
                setDefaultValue(setInputState, name, defaultValue)
            } else {
                setInputState((prev: any) => ({ ...prev, [name]: null }))
            }
        }
    })

    useEffect(() => {
        dispatch(
            modifyActionParameter({
                path,
                data: inputState,
            })
        )
    }, [debouncedInputState])

    useEffectAfterMount(() => {
        const isCurrentValueValid = isInputStateValid()
        if (isActionFieldInvalid === isCurrentValueValid) {
            if (isCurrentValueValid) {
                dispatch(setActionValid({ path }))
            } else {
                dispatch(setActionInvalid({ path }))
            }
        }
    }, [debouncedInputState])

    return <div className={`${!wrapper ? 'w-100' : ''}`}>{DurationInput}</div>
}
