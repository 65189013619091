import BlockDictionaryRow from '../../../models/BlockDictionary/components/BlockDictionaryRow'
import { RowAdding } from '../../../models/BlockDictionary/components/RowAdding'
import { ModelTypes } from '../../../models/types'
import React, { useState } from 'react'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { modifyActionParameter } from '../../../../store/actions/actionsActions'
import { WrappedBlockACTN } from '../BlockACTN/WrappedBlockACTN'
import { useInitialEffect } from '../../../../hooks/useInitialEffect'

interface BlockDictionaryACTNPRops {
    name: string
    data: any
    schema: any
    dataPath: string[]
    wrapper?: string
}

export const BlockDictionaryACTN = (props: BlockDictionaryACTNPRops) => {
    const { name, data, schema, dataPath, wrapper } = props
    const { is_required: isRequired } = schema
    const dispatch = useAppDispatch()
    const path = [...(dataPath || []), name]
    const [dictKeys, setDictKeys] = useState(Object.keys(data || {}))

    const handleAddDictValue = (dataItem: Record<string, string>) => {
        dispatch(
            modifyActionParameter({
                path,
                data: structuredClone({ [name]: { ...data, ...dataItem } }),
            })
        )
    }

    useInitialEffect(() => {
        if (isRequired && !data) {
            dispatch(modifyActionParameter({ path, data: { [name]: {} } }))
        }
    })

    return (
        <WrappedBlockACTN
            key={path + name}
            data={data}
            dataSchema={schema}
            title={name}
            name={name}
            elements={[
                <div
                    className="d-flex flex-wrap align-items-center p-1 pb-2 pt-2"
                    id={'id'}
                >
                    {dictKeys.map((dictKey: string) => (
                        <BlockDictionaryRow
                            key={dictKey}
                            dictionaryKey={dictKey}
                            value={data?.[dictKey] ?? ''}
                            data={data}
                            dataSchema={schema}
                            dataPath={dataPath}
                            name={name}
                            setDictKeys={setDictKeys}
                        />
                    ))}
                    <RowAdding
                        setDictKeys={setDictKeys}
                        schema={schema.children}
                        path={path}
                        data={data}
                        actionAddDictValue={handleAddDictValue}
                    />
                </div>,
            ]}
            wrapper={wrapper || ModelTypes.BLOCK_DICTIONARY}
            dataPath={dataPath}
        />
    )
}
