import {
    INotificationReducer,
    NotificationsActions,
    NotificationStatus,
} from '../../types/notifications'

const initialState: INotificationReducer = {
    notifications: [],
    hasMore: false,
    isLoading: false,
}

export const notificationsReducer = (
    state = initialState,
    action: { type: string; payload: any }
): INotificationReducer => {
    switch (action.type) {
        case NotificationsActions.FETCH_NOTIFICATIONS_SUCCESS: {
            return { ...state, ...(action.payload || {}) }
        }

        case NotificationsActions.FETCH_NOTIFICATIONS_ONSCROLL: {
            return {
                ...state,
                notifications: [...state.notifications, ...action.payload.notifications],
                hasMore: action.payload.hasMore
            }
        }

        case NotificationsActions.NEW_NOTIFICATIONS: {
            return {
                ...state,
                notifications: [...action.payload, ...state.notifications],
            }
        }

        case NotificationsActions.MARK_NOTIFICATION: {
            const { notificationId, status } = action.payload
            const notifications = state.notifications.map((notification) => {
                if (notification.notification_id === notificationId) {
                    return { ...notification, status }
                }
                return notification
            })
            return { ...state, notifications }
        }

        case NotificationsActions.MARK_NOTIFICATIONS_READ: {
            const notifications = state.notifications.map((notification) => ({
                ...notification,
                status: NotificationStatus.READ,
            }))

            return { ...state, notifications }
        }

        default: {
            return state
        }
    }
}
