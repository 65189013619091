import { Button, Form, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import style from './UploadConfigModal.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeEvent, DragEvent, useState } from 'react'
import { hideModal } from '../../../store/actions/modalActions'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons/faFileInvoice'
import { uploadProductConfig } from '../../../store/actions/products/productsActions'
import { useTypedSelector } from '../../../hooks/useTypedSelector'

export const UploadConfigModal = () => {
    const dispatch = useAppDispatch()

    const [dragging, setDragging] = useState(false)
    const [selectedConfigName, setSelectedConfigName] = useState('')
    const [uploadedConfig, setUploadedConfig] = useState<File | ''>('')
    const {
        data: { guid },
    } = useTypedSelector((store) => store.modal)

    const { productsConfigurations } = useTypedSelector(
        (store) => store.configurations
    )
    const productSchemaAndConfig = productsConfigurations[guid]
    const configuration = productSchemaAndConfig.configuration
    const configNames = Object.keys(configuration)

    const handleClose = (): void => {
        dispatch(hideModal())
    }

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        setDragging(true)
    }

    const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setDragging(false)
    }

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setDragging(false)
        const configFile = e.dataTransfer?.files?.[0]
        if (configFile) {
            setUploadedConfig(configFile)
        }
    }

    const handleSelectedConfigNameChange = (e: ChangeEvent<HTMLInputElement>) =>
        setSelectedConfigName(e.target.value)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const configFile = e.target.files?.[0]
        if (configFile) {
            setUploadedConfig(configFile)
        }
    }

    const handleSubmit = () => {
        const formData = new FormData()
        formData.append('file', uploadedConfig)
        formData.append('config_key', selectedConfigName)

        if (uploadedConfig instanceof File) {
            dispatch(uploadProductConfig(guid, formData, uploadedConfig.name))
        }
        setTimeout(() => handleClose(), 0)
    }

    const isValid = !!uploadedConfig && !!selectedConfigName

    return (
        <>
            <div className="bg-blur" />
            <Modal
                centered
                show={true}
                onHide={handleClose}
            >
                <Modal.Header
                    className="px-4 py-3"
                    closeButton
                >
                    <Modal.Title>
                        <FormattedMessage id="modals.configs.uploadTitle" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5 py-4">
                    <Form className="pb-2">
                        {configNames.map((configName) => (
                            <Form.Check
                                key={configName}
                                value={configName}
                                id={configName}
                                onChange={handleSelectedConfigNameChange}
                                type="radio"
                                className="mb-2"
                                name="configsNameGroup"
                                label={
                                    <>
                                        <span className="mx-1">
                                            {configName}
                                        </span>
                                        <FontAwesomeIcon icon={faFileInvoice} />
                                    </>
                                }
                            />
                        ))}
                    </Form>
                    <div
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        className={`${style.body} app-color-default ${
                            dragging && style.dragging
                        }`}
                    >
                        <Form.Control
                            className="w-75 app-color-default"
                            type="file"
                            size="sm"
                            id="uploadConfigInput"
                            onChange={handleChange}
                            style={{ display: 'none' }}
                        />
                        <Form.Label
                            htmlFor="uploadConfigInput"
                            className={` pointer px-2`}
                        >
                            <FontAwesomeIcon
                                className={style.fileIcon}
                                icon={faFileInvoice}
                            />
                            Choose File
                        </Form.Label>
                        <span className={style.inputLabel}>
                            {uploadedConfig && uploadedConfig.name}
                        </span>
                    </div>
                </Modal.Body>
                <div>
                    <Modal.Footer className="px-4 py-3">
                        <Button
                            variant="primary"
                            className="app-btn-main app-btn-apply me-0"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={!isValid}
                        >
                            <FormattedMessage id="buttons.upload" />
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    )
}
