import style from '../NotificationsModal.module.scss'
import { Modal } from 'react-bootstrap'
import React from 'react'

interface NotificationsHeaderProps {
    notificationsCount: number
    handleFilterClose: () => void
}

export const NotificationsHeader = (props: NotificationsHeaderProps) => {
    const { notificationsCount, handleFilterClose } = props

    return <Modal.Header
        onClick={handleFilterClose}
        className={style.header}
    >
        <Modal.Title>
            Notifications &nbsp;
            <span className={style.notisCount}>
                        {notificationsCount}
                    </span>
        </Modal.Title>
    </Modal.Header>
}