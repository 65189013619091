import { receiveLiveLogs } from './receiveProductLogs'
import { receiveNotifications } from './receiveNotifications'
import { receiveProductStatus } from './receiveProductStatus'
import { HubConnection } from '@microsoft/signalr'
import { AppDispatch } from '../types/store'
import { receiveNewProduct } from './receiveNewProduct'

export const startSignalRCommunication = async (
    connection: HubConnection,
    dispatch: AppDispatch
) => {
    try {
        receiveLiveLogs(connection, dispatch)
        receiveNotifications(connection, dispatch)
        receiveProductStatus(connection, dispatch)
        receiveNewProduct(connection, dispatch)
        await connection.start()
    } catch (e) {
        console.error(e)
    }
}
