export const getDoubleSchema = (inputString: any, schema: any, value?: any) => {
  inputString = inputString.test('Is NaN', (val: any) => {
    if (val && val !== 'null' && val !== '0') {
      if (val.includes(',')) {
        return Number(val.replace(',', '.'))
      }
      return Number(val)
    }
    return true
  })

  inputString = inputString.test('Is less than MAX_SAFE_INTEGER', (val: any) => {
    if (!schema.is_required && isValidEmptyValue(val)) {
      return true
    }
    return Number(val) <= Number.MAX_SAFE_INTEGER
  })

  inputString = inputString.test('Is more than MIN_SAFE_INTEGER', (val: any) => {
    if (!schema.is_required && isValidEmptyValue(val)) {
      return true
    }
    return Number(val) >= Number.MIN_SAFE_INTEGER
  })

  if (typeof schema.greater_or_equal !== 'undefined') {
    inputString = inputString.test('Is greater or equal', (val: any) => {
      if (!schema.is_required && isValidEmptyValue(val)) {
        return true
      }
      return Number(val) >= schema.greater_or_equal
    })
  }
  if (typeof schema.greater_than !== 'undefined') {
    inputString = inputString.test('Is greater than', (val: any) => {
      if (!schema.is_required && isValidEmptyValue(val)) {
        return true
      }
      return Number(val) > schema.greater_than
    })
  }
  if (typeof schema.less_or_equal !== 'undefined') {
    inputString = inputString.test('Is less or equal', (val: any) => {
      if (!schema.is_required && isValidEmptyValue(val)) {
        return true
      }
      return Number(val) <= schema.less_or_equal
    })
  }
  if (typeof schema.less_than !== 'undefined') {
    inputString = inputString.test('Is less than', (val: any) => {
      if (!schema.is_required && isValidEmptyValue(val)) {
        return true
      }
      return Number(val) <= schema.less_than
    })
  }
  if (typeof schema.precision !== 'undefined') {
    inputString = inputString.test('Number of fraction digits allowed', (val: any) => {
      if (!schema.is_required && isValidEmptyValue(val)) {
        return true
      }
      if (val && hasFractionDigits(val)){
        return String(val).split('.')[1].length <= Number(schema.precision)
      }
      return 0 <= Number(schema.precision)
    })
  }

  if (schema.is_required) {
    inputString = inputString.required()
  }
  if (!schema.is_required) {
    inputString = inputString
      .notRequired()
      .nullable()
      .transform((_: any, val: any) => String(val) || null)
  }
  return inputString
}

function isValidEmptyValue(val: any) {
  return val === '' || val === null || val === 'null'
}

function hasFractionDigits (val: any) {
  return val.toString().split('.')?.[1]
}