import { StoreSchema } from '../../../types/store'
import { createSelector } from 'reselect'
import { getActionFieldErrorKey } from '../utils/getActionFieldErrorKey'

export const selectInvalidActions = (store: StoreSchema) =>
    store.actions.invalidActions

export const selectInvalidAction = (guid: string, actionName: string) => createSelector([selectInvalidActions], (invalidActions) => invalidActions[guid]?.[actionName])

export const selectInvalidActionByErrorKey = (path: string[]) =>
    createSelector([selectInvalidActions], (invalidActions) => {
        const [guid, actionName] = path
        const errorKey = getActionFieldErrorKey(path)

        return invalidActions[guid]?.[actionName]?.[errorKey]
    })
