import { useEffect, useRef, useState } from 'react'

export const useDebouncedValue = (value: any, delay: number) => {
    const [prevValue, setPrevValue] = useState(value)
    const [returnValue, setReturnValue] = useState()

    const timer = useRef<undefined | NodeJS.Timeout>()

    useEffect(() => {
        if (prevValue !== value) {
            if (timer.current) {
                clearTimeout(timer.current)
            }
            timer.current = setTimeout(() => {
                setReturnValue(value)
                setPrevValue(value)
            }, delay)
        }
    }, [value, delay])

    return returnValue
}
