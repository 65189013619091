import { IProduct } from '../types/product'
import { HubConnection } from '@microsoft/signalr'

export const subscribeToProductLogs = async (
    connection: HubConnection,
    products: IProduct[] = []
) => {
    try {
        if (!products.length) {
            throw 'No products to subscribe to'
        }
        const subscriptions = products?.map((product: IProduct) => {
            return connection.send('log_subscription', product?.guid)
        })
        await Promise.allSettled(subscriptions)
        console.log('Subscription to products logs success')
    } catch (e) {
        console.error(e)
    }
}
