import { ILogsReducer, LogsActions } from '../../types/logs'

const initialState: ILogsReducer = {
    productsLiveLogs: {},
    productLogs: [],
    isLoading: false,
}

export const logsReducer = (
    state = initialState,
    action: { type: string; payload: any }
): ILogsReducer => {

    switch (action.type) {
        case LogsActions.FETCH_PRODUCT_LIVE_LOGS_SUCCESS: {
            const { productGuid, logsBatch } = action.payload
            return {
                ...state,
                productsLiveLogs: {
                    ...state.productsLiveLogs,
                    [productGuid]: logsBatch
                }
            }
        }
        case LogsActions.NEW_LIVE_LOGS: {
            const { productGuid, liveLogs } = action.payload
            return {
                ...state,
                productsLiveLogs: {
                    ...state.productsLiveLogs,
                    [productGuid]: [...state.productsLiveLogs[productGuid], ...liveLogs]
                }
            }
        }
        case LogsActions.FETCH_PRODUCT_LOGS_LIST_SUCCESS: {
            return {
                ...state,
                productLogs: action.payload
            }
        }
        case LogsActions.DOWNLOAD_PRODUCT_LOGS_SUCCESS: {
            return { ...state }
        }
        case LogsActions.DOWNLOAD_SERVER_LOGS: {
            return { ...state, isLoading: true }
        }
        case LogsActions.DOWNLOAD_SERVER_LOGS_SUCCESS: {
            return { ...state, isLoading: false }
        }
        case LogsActions.DOWNLOAD_SERVER_LOGS_ERROR: {
            return { ...state, isLoading: false }
        }

        default: {
            return state
        }
    }
}
