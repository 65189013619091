import React, { FC, useState } from 'react'
import { Options } from 'overlayscrollbars'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import style from '../Styles/index.module.scss'
import { Card, Collapse } from 'react-bootstrap'
import { IProduct } from '../../../types/product'
import { FormattedMessage } from 'react-intl'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { UnsavedChangesMark } from '../../UnsavedChangesMark'
import ProductCardProductStatus from './ProductCardProductStatus'
import { useExpand } from '../../../hooks/useExpand'

interface IProductCardBody {
    product: IProduct
}

const ProductCardBody: FC<IProductCardBody> = ({ product }) => {
  const { name, status, version, guid, path, ip, type } = product
  const { unsaved } = useTypedSelector((state) => state.configurations)
  const [isAppNameHovered, setIsAppNameHovered] = useState(false)
  const onAppNameHover = () => setIsAppNameHovered(true)
  const onAppNameLeave = () => setIsAppNameHovered(false)
  const isUnsaved: boolean = unsaved[guid]
  const {expandBtn, expanded, toggle} = useExpand({
    isExpanded: false,
    uniqueID: path,
    ariaID: path,
    className: 'ms-2 btn-clean'
  })

  return (
    <Card.Body>
      <div className="mt-2">
        <ProductCardProductStatus
            guid={guid}
            status={status}
            productType={type}
            isCard
        />
        <hr />
        <div className={style.descriptionAppName}>
          <span className="app-color-text fw-500 me-1">
            <FormattedMessage id="productCard.appName" />
          </span>
          {!isAppNameHovered ? (
            <span
              className={style.descriptionNotHovered}
              onMouseEnter={onAppNameHover}
            >
              {name}
            </span>
          ) : (
            <OverlayScrollbarsComponent
              className={style.descriptionHovered}
              options={
                {
                  overflowBehavior: { x: 'scroll', y: 'visible-hidden' },
                } as Options
              }
              onMouseLeave={onAppNameLeave}
            >
              {name}
            </OverlayScrollbarsComponent>
          )}
        </div>

        <div className={style.description}>
          <span className="app-color-text fw-500 me-1">
            <FormattedMessage id="productCard.version" />
          </span>
          {version}
        </div>

        <div>
          {isUnsaved ? (
            <div className="app-bg-gray ps-1 pe-1 fit-content">
              <UnsavedChangesMark className="me-1 app-color-default" />
              <FormattedMessage id="productCard.unsavedChanges" />
            </div>
          ) : (
            <FormattedMessage id="productCard.upToDate" />
          )}
        </div>

        <div>
          <div className={style.control}>
            <span
              onClick={toggle}
              className={style.showInfo}
              aria-controls={guid}
              aria-expanded={expanded}
            >
              {expanded ? <FormattedMessage id="productCard.hideInfo" /> : <FormattedMessage id="productCard.showInfo" />}
              {expandBtn}
            </span>
          </div>
          <Collapse in={expanded}>
            <div id={guid} className="border p-1 rounded mt-2">
             <p><span className="app-color-text fw-500 me-2">{'Path:'}</span>{path || '/'}</p>
             <p><span className="app-color-text fw-500 me-2">{'IP:    '}</span>{ip || '...'}</p>
            </div>
          </Collapse>
        </div>
      </div>
    </Card.Body>
  )
}

export default ProductCardBody
