import {
    INotification,
    NotificationStatus,
} from '../../../../types/notifications'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { markNotification } from '../../../../store/actions/notificationsActions'
import { withTooltip } from 't4b-core-frontend'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import style from '../NotificationsModal.module.scss'
import { faCircle } from '@fortawesome/free-regular-svg-icons/faCircle'
import { faCircleDot } from '@fortawesome/free-regular-svg-icons/faCircleDot'
import React from 'react'

export const MarkNotificationBtn = ({
    notification,
}: {
    notification: INotification
}) => {
    const dispatch = useAppDispatch()

    const isRead = notification.status === NotificationStatus.READ
    const tooltip = isRead ? 'Mark as unread' : 'Mark as read'

    const handleMarkNotification = () => {
        const status = isRead
            ? NotificationStatus.UNREAD
            : NotificationStatus.READ
        dispatch(markNotification(notification.notification_id, status))
    }

    return withTooltip(
        <button
            onClick={handleMarkNotification}
            className={`${style.markBtn} btn-clean rounded`}
        >
            <FontAwesomeIcon
                className={style.checkbox}
                icon={notification.status === 'read' ? faCircle : faCircleDot}
            />
        </button>,
        tooltip,
        'notificationMark',
        'right'
    )
}
