import React, { useState } from 'react'
import style from '../../../models/Block/Styles/index.module.scss'
import { getLabel } from '../../../../validators'
import PromptBtn from '../../../buttons/PromptBtn/PromptBtn'
import { blockConstructor } from '../../../models/Block/control'
import { IBlockProps } from '../../../models/Block/types'
import { Collapse } from 'react-bootstrap'
import { generateAriaId } from '../../utils/generateAriaId'
import { generateUniqueLocalId } from '../../utils/generateUniqueLocalId'
import { useExpand } from '../../../../hooks/useExpand'

export const BlockACTN = (props: IBlockProps) => {
    const {
        name = '',
        dataSchema = {},
        data,
        dataPath = [],
        title = '',
        className,
        wrapper,
        element_id,
    } = props

    const [path] = useState([...dataPath, name])

    const { is_auxiliary: isCollapsed, description } = dataSchema
    const localId = generateUniqueLocalId({ path, name, element_id })
    const ariaID = generateAriaId()
    const collapseBtnClassName = style.button

    const { expandBtn, expanded, toggle } = useExpand({
        isExpanded: !isCollapsed,
        uniqueID: localId,
        ariaID,
        className: collapseBtnClassName,
    })

    const label = title ? title : getLabel(name)
    const styleHeader = `${style.header} app-bg-navi-blue rounded-top`

    const block = blockConstructor(
        dataSchema,
        data,
        data || {},
        name,
        path,
        dataPath,
        true,
        2,
        element_id,
        undefined,
        true,
        undefined,
        'actionUiBuilder'
    )

    const desc = !!description && (
        <PromptBtn
            className="ms-auto me-2 text-white"
            size="small"
            prompt={description}
        />
    )

    return (
        <div
            className={`${className} w-100 border-left border-right border-bottom mw-350 d-flex flex-column rounded border mt-2 mb-2 position-relative me-4 align-self-start`}
        >
            <div className={styleHeader}>
                <div
                    className={!wrapper ? 'w-100' : ''}
                    onClick={toggle}
                >
                    <h4 className={style.title}>
                        {wrapper === 'block_dictionary_card' ? (
                            <span style={{ opacity: 0 }}>{name}</span>
                        ) : (
                            label
                        )}
                    </h4>
                </div>
                {desc}
                {expandBtn}
            </div>
            <Collapse in={expanded}>
                <div className="p-relative">
                    <div
                        className="p-3 app-bg-primary rounded-bottom"
                        id={ariaID}
                    >
                        {block}
                    </div>
                </div>
            </Collapse>
        </div>
    )
}
