import styles from './ActionTab.module.scss'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import React, { memo } from 'react'
import { convertToSentenceCase } from '../../../utils/convertToSentenceCase'
import { selectOneCustomAction } from '../selectors/actionsSelectors'

interface ActionTabProps {
    guid: string
    actionName: string
    activeActionName: string
    onActionTabClick: (actionName: string) => void
}

export const ActionTab = memo(function ActionTab(props: ActionTabProps) {
    const { guid, actionName, activeActionName, onActionTabClick } = props

    const currentAction = useTypedSelector(selectOneCustomAction(guid, actionName))
    const actionNameBeautified = convertToSentenceCase(actionName)
    const isActive = activeActionName === actionName
    const handleActionClick = () => onActionTabClick(actionName)

    return (
        <div
            className={`${styles.actionTab} ${isActive && styles.active}`}
            onClick={handleActionClick}
        >
            <strong>{actionNameBeautified}</strong>
            {!!currentAction.description && (
                <p className={styles.description}>
                    {currentAction.description}
                </p>
            )}
        </div>
    )
})
