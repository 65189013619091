import React, { FC, MutableRefObject } from 'react'
import { Overlay, Popover } from 'react-bootstrap'
import { Placement } from 'react-bootstrap/esm/types'

interface IAppPopover {
  placement?: Placement
  show: boolean
  target: HTMLElement | null
  container: MutableRefObject<null>
  containerPadding?: number
  className?: string
  style?: React.CSSProperties
  children: JSX.Element | JSX.Element[]
}

export const AppPopover: FC<IAppPopover> = ({
  placement,
  show,
  target,
  container,
  className,
  style,
  children,
  containerPadding,
}) => {
  return (
    <Overlay
      show={show}
      target={target}
      placement={placement || 'top'}
      container={container}
      containerPadding={containerPadding || 20}
    >
      <Popover
        className={className}
        style={{ ...style }}
        id="popover-contained"
      >
        {children}
      </Popover>
    </Overlay>
  )
}
