import React, { memo, useCallback, useState } from 'react'
import { ActionStartReset } from '../ActionStartReset/ActionStartReset'
import { ActionParameters } from '../ActionParameters/ActionParameters'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { selectOneCustomAction } from '../selectors/actionsSelectors'
import styles from './ActionUi.module.scss'

interface ActionUiProps {
    guid: string
    actionName: string
}

export const ActionUi = memo(function ActionUi(props: ActionUiProps) {
    const { guid, actionName } = props

    const [remountKey, setRemountKey] = useState(Math.random)
    const customAction = useTypedSelector(selectOneCustomAction(guid, actionName)) ?? {}
    const hasParameters = Object.keys(customAction?.request_schema).length

    const resetActionParametersComponent = useCallback(
        (newKey: number) => setRemountKey(newKey),
        []
    )

    return (
        <div>
            {!!hasParameters ? (
                <>
                    <ActionParameters
                        key={`${guid}-${actionName}-${remountKey}`}
                        guid={guid}
                        actionName={actionName}
                    />
                    <ActionStartReset
                        guid={guid}
                        actionName={actionName}
                        onReset={resetActionParametersComponent}
                    />
                </>
            ) : (
                <div className={styles.noParams}>No parameters</div>
            )}
        </div>
    )
})
