import { useAppDispatch } from '../../../../../hooks/useAppDispatch'
import React from 'react'
import RemoveItemBtn from '../../../../buttons/RemoveItemBtn/RemoveItemBtn'
import { deleteItemFromArray } from '../../../../../store/actions/actionsActions'
import { buildActionsUI } from '../../../buildActionsUI'

interface CardACTNProps {
    config: any
    schema: any
    dataPath: any
    index: number
    element_id: string
    position: number
}

export const CardACTN = (props: CardACTNProps) => {
    const { config, schema, dataPath, index, element_id, position } =
        props
    const dispatch = useAppDispatch()

    const cardScheme = schema.children?.children || schema.children
    const cardSchemeKeys = Object.keys(cardScheme || {})

    const path = [...(dataPath || []), index]

    const onDeleteCardHandle = () => dispatch(deleteItemFromArray({ path }))
    const removeItemButton = (
        <RemoveItemBtn
            data={{
                index,
                body: `Remove item ${index + 1}?`,
                onSubmit: onDeleteCardHandle,
            }}
        />
    )

    const inputs = cardSchemeKeys.map((el: string, i: number) => (
        <div key={i}>
            {buildActionsUI(
                el,
                cardScheme[el],
                config,
                'block_array',
                path,
                false,
                3,
                element_id,
                true // inCard value initiation
            )}
        </div>
    ))

    return (
        <div className="d-flex flex-wrap justify-content-between position">
            <div className="rounded w-100 array-item-header p-1 border app-bg-middle-blue text-capital">
                <div className="px-2 py-1 text-light d-flex gap-2">
                    {position}
                    {removeItemButton}
                </div>
            </div>
            <div className="p-3 w-100">{inputs}</div>
        </div>
    )
}
