import { Collapse } from 'react-bootstrap'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { IProduct } from '../../../types/product'
import { NoData } from '../../NoData'
import ProductCard from '../../ProductCard/ProductCard'
import style from '../Styles/index.module.scss'
import { getOtherProducts } from '../../../utils/filters'
import { useEffectAfterMount } from '../../../hooks/useEffectAfterMount'
import { useExpand } from '../../../hooks/useExpand'

interface IProductsOthersProps {
    productsList: IProduct[]
    hideInactive: boolean
}

export const ProductsOthers = (props: IProductsOthersProps) => {
  const { productsList, hideInactive } = props

  const { productsGroups } = useTypedSelector((state) => state.products)
  const othersProductsList = getOtherProducts(productsGroups, productsList)

  const groupId = 'others-products-group'
  const { expandBtn, expanded, expand, toggle} = useExpand({
    isExpanded: true,
    uniqueID: groupId,
    ariaID: groupId,
    className: 'ms-4 btn-clean'
  })

  useEffectAfterMount(() => {
    if (!productsGroups.length) expand()
  }, [productsGroups])

  return (
    <>
      {!othersProductsList.length && productsList.length ? (
        <></>
      ) : (
        <>
          <div className={style.groupOthers}>
            {productsGroups.length ? (
              <div
                className={style.header}
                aria-controls="collapse-card"
                aria-expanded={expanded}
              >
                <div onClick={toggle} className={style.container}>
                  <h4 className={style.title}>Other products</h4>
                  {expandBtn}
                </div>
              </div>
            ) : (
              <></>
            )}
            <Collapse in={expanded}>
              <div id="collapse-card">
                {!othersProductsList.length && hideInactive ? (
                  <NoData
                    style={{ height: '150px' }}
                    messageId="summary.noActiveProductsInGroup"
                  />
                ) : (
                  <div className="d-flex flex-wrap px-3">
                    {othersProductsList.map((product: IProduct) => (
                      <ProductCard product={product} key={product.guid} />
                    ))}
                  </div>
                )}
              </div>
            </Collapse>
          </div>
        </>
      )}
    </>
  )
}
