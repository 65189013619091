import { StoreSchema } from '../../../types/store'
import { createSelector } from 'reselect'

export const selectCustomActions = (store: StoreSchema) =>
    store.actions.customActions

export const selectProductCustomActions = (guid: string) =>
    createSelector(
        [selectCustomActions],
        (customActions) => customActions[guid]
    )

export const selectOneCustomAction = (guid: string, actionName: string) =>
    createSelector(
        [selectCustomActions],
        (customActions) => customActions[guid]?.[actionName]
    )
