import React from 'react'
import AppButton from '../components/buttons/AppButton/AppButton'

export const getBlockFooterData = (
    keys: string[],
    checkLength: number,
    onAddHandle: Function,
    tooltip: string,
    data: JSX.Element[] = []
): JSX.Element[] => {
    const footerItems =
        keys.length > checkLength
            ? [
                  <AppButton
                      variant="add"
                      onClick={onAddHandle}
                      className="btn btn-add p-1 me-2 ms-auto ps-3 pe-3"
                      tooltip={tooltip}
                  />,
                  ...data,
              ]
            : []

    return footerItems.map((el: any, i: number) => <div key={i}>{el}</div>)
}
