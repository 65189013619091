import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { Modal } from 'react-bootstrap'
import { hideModal } from '../../../store/actions/modalActions'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import style from './NotificationsModal.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import {
    fetchNotifications,
    fetchNotificationsOnScroll,
    markFilteredNotificationsRead,
} from '../../../store/actions/notificationsActions'
import { Notification } from './Notificaation/Notificaation'
import { FormattedMessage } from 'react-intl'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'
import { NotificationsFilter } from './NotificationsFilter/NotificationsFilter'
import { getNotificationsVisible } from './utils/getNotificationsVisisble'
import { useSessionStorage } from '../../../hooks/useSessionStroage'
import { getNotificationsFilter } from './utils/getNotificationsFilter'
import { NotificationsHeader } from './NotificationsHeader/NotificationsHeader'
import { MarkAsReadControls } from './MarkAsReadControls/MarkAsReadControls'

export const NotificationsModal = () => {
    const dispatch = useAppDispatch()
    const [filterOpen, setFilterOpen] = useState(false)
    const [checkedProducts, setCheckedProducts] = useSessionStorage<string[]>(
        'notificationsFilter',
        []
    )
    const { notifications, hasMore } = useTypedSelector(
        (store) => store.notifications
    )
    const [notificationsVisible, setNotificationsVisible] = useState(
        getNotificationsVisible(checkedProducts, notifications)
    )

    const unreadNotificationIds = useMemo(
        () =>
            notificationsVisible.reduce((acc: string[], notis) => {
                if (notis.status === 'unread') {
                    acc = [...acc, notis.notification_id]
                }
                return acc
            }, []),
        [notificationsVisible]
    )

    const handleFilterClose = () => setFilterOpen(false)
    const handleModalClose = () => dispatch(hideModal())

    const handleReadFiltered = () => {
        dispatch(markFilteredNotificationsRead(checkedProducts))
    }
    const disableReadFiltered =
        !unreadNotificationIds.length || !checkedProducts.length

    const handleLoadMore = () => {
        const lastNotificationId = notificationsVisible.at(-1)?.notification_id
        const notificationsFilterProductsIds = getNotificationsFilter()
        dispatch(
            fetchNotificationsOnScroll(
                lastNotificationId,
                notificationsFilterProductsIds
            )
        )
    }

    useEffect(() => {
        const notificationsFilterProductsIds = getNotificationsFilter()
        dispatch(fetchNotifications(notificationsFilterProductsIds))
    }, [checkedProducts.length, dispatch])

    useEffect(() => {
        setNotificationsVisible(
            getNotificationsVisible(checkedProducts, notifications)
        )
    }, [notifications, checkedProducts.length])

    return (
        <Modal
            show
            scrollable
            onHide={handleModalClose}
            dialogClassName={style.customModal}
            backdropClassName={style.customBackdrop}
        >
            <NotificationsHeader
                notificationsCount={unreadNotificationIds.length}
                handleFilterClose={handleFilterClose}
            />
            <div className={style.controls}>
                {!!notifications.length && (
                    <MarkAsReadControls
                        handleReadFiltered={handleReadFiltered}
                        disableReadFiltered={disableReadFiltered}
                    />
                )}
                <NotificationsFilter
                    filterOpen={filterOpen}
                    setFilterOpen={setFilterOpen}
                    checkedProducts={checkedProducts}
                    setCheckedProducts={setCheckedProducts}
                />
            </div>
            <Modal.Body
                onClick={handleFilterClose}
                className={style.customBody}
            >
                <div className={style.notisContainer}>
                    {!!notificationsVisible.length ? (
                        notificationsVisible.map((notis) => (
                            <Notification
                                key={notis.notification_id}
                                notification={notis}
                            />
                        ))
                    ) : (
                        <div className={style.noData}>No data</div>
                    )}
                    {!!notifications.length && hasMore && (
                        <button
                            onClick={handleLoadMore}
                            className={`${style.more} m-auto`}
                        >
                            <FormattedMessage id="buttons.moreNotifications" />
                            <span className="ms-2">
                                <FontAwesomeIcon icon={faAngleDown} />
                            </span>
                        </button>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    )
}
