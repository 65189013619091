import { HubConnection } from '@microsoft/signalr'
import { AppDispatch } from '../types/store'
import { IProduct } from '../types/product'
import { fetchProducts } from '../store/actions/products/productsActions'

export const receiveNewProduct = (
    connection: HubConnection,
    dispatch: AppDispatch
) => {
    connection.on('new_product', (product: IProduct) => {
        if (product) dispatch(fetchProducts())
    })
}
