import React, { FC } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import style from './Styles/index.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux'
import { showModal } from '../../../store/actions/modalActions'
import { ModalTypes } from '../../../types/modals'
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark'

interface RemoveItemBtnProps {
    className?: string
    tooltip?: string
    data?: any
    position?: string
}

const RemoveItemBtn: FC<RemoveItemBtnProps> = ({
  className,
  tooltip,
  data,
  position,
}) => {
  const dispatch = useDispatch()

  const handleShow = (): void => {
    dispatch(showModal(ModalTypes.MAIN_MODAL, data))
  }

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="tooltip-top">{tooltip ?? 'Remove item'}</Tooltip>}
    >
      {!position ? (
        <button
          style={{ position: 'absolute', top: '10px', right: 0 }}
          className={[style.button, className, 'icon-main'].join(' ')}
          onClick={handleShow}
        >
          <span>
            <FontAwesomeIcon icon={faXmark} />
          </span>
        </button>
      ) : (
        <button
          className={[style.button, className, 'icon-main'].join(' ')}
          onClick={handleShow}
        >
          <span>
            <FontAwesomeIcon icon={faXmark} />
          </span>
        </button>
      )}
    </OverlayTrigger>
  )
}

export default RemoveItemBtn
