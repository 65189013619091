import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useFormValidation } from '../../../../hooks/useFormValidation'
import { ConfigurationsActionType } from '../../../../types/configurations'
import { inputValidationCheck } from '../../../../validators/inputValidators/inputValidators'
import { buildControlsExt } from '../../../inputs/controls'
import { AppTooltip } from '../../../overlays/AppTooltip'
import { inputCall } from '../../control'
import style from '../Styles/index.module.scss'
import { checkUnsavedChanges } from '../../../../store/actions/configurationActions'

interface IRowAdding {
    path: string[]
    data: any
    schema: any
    setDictKeys?: any
    actionAddDictValue?: (item: Record<string, string>) => void
}

export const RowAdding: FC<IRowAdding> = ({
  path,
  data,
  schema,
  setDictKeys,
  actionAddDictValue,
}) => {
  const dispatch = useDispatch()
  const [isAdding, setIsAdding] = useState(false)
  const initialState = { key: '', value: '' }
  const keySchema = inputValidationCheck(schema.key.type, {
    ...schema.key,
    is_required: true,
  })
  const valueSchema = inputValidationCheck(schema.value.type, {
    ...schema.value,
    is_required: true,
  })

  const validationCheck = { key: keySchema, value: valueSchema }
  const [inputState, setInputState, touched, setTouched, validationResult] =
    useFormValidation(initialState, validationCheck)

  const newItemForm = buildControlsExt(
    [
      inputCall({
        type: schema.key.type,
        name: 'key',
        isLabelHidden: true,
        schema: schema.key,
        isFeedbackFixed: true,
        className: 'app-dictionary-row-input',
        state: inputState,
      }),
      inputCall({
        type: schema.value.type,
        name: 'value',
        isLabelHidden: true,
        schema: schema.value,
        isFeedbackFixed: true,
        className: 'ml-2 app-dictionary-row-input',
        state: inputState,
      }),
    ],
    inputState,
    setInputState,
    '',
    touched,
    setTouched,
    validationResult
  )

  const isNewRowValid =
    !validationResult.key &&
    !validationResult.value &&
    inputState.key &&
    inputState.value

  const handleShow = (): void => {
    setIsAdding((isAdding) => !isAdding)
  }

  const cleanForm = (): void => {
    setInputState(initialState)
    setTouched({})
  }

  const onCancelHandle = (): void => {
    setIsAdding(false)
    cleanForm()
  }

  const onAddDiction = (): void => {
      const item = { [String(inputState.key)]: inputState.value }

    if (isNewRowValid) {
        if (actionAddDictValue) {
            actionAddDictValue(item)
        } else {
          dispatch({
            type: ConfigurationsActionType.BLOCK_DICTIONARY_ADD,
            payload: {
              path,
              data,
              item,
            },
          })
          dispatch(checkUnsavedChanges(path[0]))
        }
      setIsAdding(false)
      cleanForm()
        // @ts-ignore
        setDictKeys((prev: string[]) => [...new Set([...prev, inputState.key])] )
    }
  }

  if (!isAdding) {
    return (
      <AppTooltip messageId="blockDictionary.row.addNewItemTolltip">
        <button onClick={handleShow} className={style.buttonAdd}>
          <span>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        </button>
      </AppTooltip>
    )
  }

  return (
    <div className={`d-flex`}>
      <div
        style={{ width: '1px', height: '40px' }}
        className="ms-1 mb-auto mt-auto"
      />
      <div className="d-flex">{newItemForm}</div>
      <Button
        onClick={onAddDiction}
        variant="outline-primary"
        size="sm"
        className="ms-1 my-1 app-dictionary-row"
        disabled={!isNewRowValid}
      >
        <FormattedMessage id="buttons.add" />
      </Button>
      <Button
        onClick={onCancelHandle}
        variant="outline-secondary"
        size="sm"
        className="ms-1 my-1 app-dictionary-row"
      >
        <FormattedMessage id="buttons.cancel" />
      </Button>
    </div>
  )
}
