import { StoreSchema } from '../../../types/store'
import { createSelector } from 'reselect'

const selectActionsHistory = (store: StoreSchema) =>
    store.actions.actionsHistory

export const selectActionsHistoryByGuid = (guid: string) =>
    createSelector(
        [selectActionsHistory],
        (actionsHistory) => actionsHistory[guid]
    )
