import React, { memo, useState } from 'react'
import {
    postAction,
    resetActionParameters,
} from '../../../store/actions/actionsActions'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { isActionValid } from '../utils/isActionValid'
import { selectInvalidAction } from '../selectors/invalidActionsSelectors'
import { selectActionParams } from '../selectors/actionsParametersSelectors'

interface ActionStartResetProps {
    guid: string
    actionName: string
    onReset: (componentRemountKey: number) => void
}

export const ActionStartReset = memo(function ActionStartReset(
    props: ActionStartResetProps
) {
    const { guid, actionName, onReset } = props
    const dispatch = useAppDispatch()

    const [preventStart, setPreventStart] = useState(false)
    const actionParams = useTypedSelector(selectActionParams(guid, actionName))
    const invalidAC = useTypedSelector(selectInvalidAction(guid, actionName))

    const startDisabled = preventStart || !isActionValid(invalidAC ?? {})

    const handleStart = () => {
        dispatch(postAction(guid, actionName, actionParams))
        setPreventStart(true)
        setTimeout(() => setPreventStart(false), 3000)
    }

    const handleReset = () => {
        dispatch(resetActionParameters({ guid, actionName }))
        onReset(Math.random())
    }

    return (
        <div className="d-flex gap-2">
            <button
                onClick={handleReset}
                className="my-3 app-btn-main app-btn-apply"
            >
                Reset
            </button>
            <button
                onClick={handleStart}
                disabled={startDisabled}
                className="my-3 app-btn-main app-btn-reset"
            >
                Start
            </button>
        </div>
    )
})
