import { NotificationsActions } from '../types/notifications'
import { HubConnection } from '@microsoft/signalr'
import { AppDispatch } from '../types/store'

export const receiveNotifications = (
    connection: HubConnection,
    dispatch: AppDispatch
) => {
    connection.on('new_notifications', (notifications) => {
        if (Array.isArray(notifications)) {
            dispatch({
                type: NotificationsActions.NEW_NOTIFICATIONS,
                payload: notifications,
            })
        }
    })
}