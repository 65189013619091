import style from '../NotificationsModal.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { markAllNotificationsRead } from '../../../../store/actions/notificationsActions'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'

interface MarkAsReadControlsProps {
    handleReadFiltered: () => void
    disableReadFiltered: boolean
}

export const MarkAsReadControls = (props: MarkAsReadControlsProps) => {
    const {handleReadFiltered, disableReadFiltered} = props
    const dispatch = useAppDispatch()

    const handleReadAll = () => {
        dispatch(markAllNotificationsRead())
    }

    return (
        <>
            <button
                className={`btn-clean ${style.controlBtn}`}
                onClick={handleReadAll}
            >
                <FontAwesomeIcon icon={faCheck} />
                <span>Mark all as read</span>
            </button>
            <button
                className={`btn-clean ${style.controlBtn}`}
                onClick={handleReadFiltered}
                disabled={disableReadFiltered}
            >
                <FontAwesomeIcon icon={faCheck} />
                <span>Mark filtered as read</span>
            </button>
        </>
    )
}