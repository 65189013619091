export function generateUniqueLocalId({
    path,
    name,
    element_id,
}: {
    path: string[]
    name: string
    element_id?: string
}) {
    return element_id ? `${element_id}-${name}` : `${path}-${name}`
}
