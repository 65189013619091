import { getEmptyData } from '../../../../utils/InitData'
import { generateElementID } from '../../../../utils/generateElementID'

export const getEmptyCard = (schemaKeys: string[], schema: any) => {
    let result = { element_id: generateElementID() }
    schemaKeys.map(
        (el: any) => (result = { [el]: getEmptyData(schema[el]), ...result })
    )
    return result
}
