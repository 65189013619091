import style from '../../NotificationsModal.module.scss'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'

interface ViewNotificationBtnProps {
    viewMore: boolean
    onViewMore: () => void
}

export const ViewNotificationBtn = (props: ViewNotificationBtnProps) => {
    const { viewMore, onViewMore: handleViewMore } = props

    return (
        <button
            onClick={handleViewMore}
            className={style.more}
        >
            {viewMore ? (
                <>
                    <FormattedMessage id="buttons.hide" />
                    <span className="ms-2">
                        <FontAwesomeIcon icon={faAngleUp} />
                    </span>
                </>
            ) : (
                <>
                    <FormattedMessage id="buttons.more" />
                    <span className="ms-2">
                        <FontAwesomeIcon icon={faAngleDown} />
                    </span>
                </>
            )}
        </button>
    )
}
