import { getPermissions, getUsername } from '../utils/authentication'

export enum UserPermissions {
    USER_MANAGEMENT = 'user_management',
    CONFIGURATION_MANAGEMENT = 'configuration_management',
    CONFIGURATION_ADMINISTRATION = 'configuration_administration',
    USER_ADMINISTRATION = 'user_administration',
    GROUP_MANAGEMENT = 'group_management',
}

export enum UserTypes {
  OWNER = 'Owner',
  ADMINISTRATOR = 'Administrator',
  FULL_RIGHTS_MANAGER = 'Full-rights manager',
  WL_MANAGER = 'WL manager',
  NOT_STANDARD_ROLE = 'Non-standard role',
}

export const AllProductsAccessTypes: any = {
  Administrator: UserTypes.ADMINISTRATOR,
  Owner: UserTypes.FULL_RIGHTS_MANAGER,
}

interface IUser {
  login: string
  permissions: string[]
}

export class User implements IUser {
  public login: string
  public permissions: string[]

  constructor() {
    this.login = getUsername()
    this.permissions = getPermissions()
  }

  isOwner() {
    return this.permissions.includes(UserPermissions.USER_ADMINISTRATION)
  }

  isAdministratorOrOwner() {
    return this.permissions.includes(UserPermissions.USER_MANAGEMENT)
  }

  isFullRightsManagerOrAbove() {
    return this.permissions.includes(UserPermissions.CONFIGURATION_ADMINISTRATION)
  }

  isWl() {
    return this.permissions.includes(UserPermissions.CONFIGURATION_MANAGEMENT) && !this.permissions.includes(UserPermissions.CONFIGURATION_ADMINISTRATION)
  }


}
