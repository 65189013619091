import { ProductStatus } from '../entity/Product'
import { ProductActionType } from '../types/product'
import { HubConnection } from '@microsoft/signalr'
import { AppDispatch } from '../types/store'

export const receiveProductStatus = (
    connection: HubConnection,
    dispatch: AppDispatch
) => {
    const productStatuses = Object.values(ProductStatus)

    connection.on('product_status', (productId, status) => {
        if (productId && productStatuses.includes(status)) {
            dispatch({
                type: ProductActionType.CHANGE_PRODUCT_STATUS,
                payload: { productId, status },
            })
        }
    })
}