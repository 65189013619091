import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { blockArrayTypeCheck } from '../../../models/BlockArray/utils/blockArrayTypeCheck'
import React, { useState } from 'react'
import { BlockArrayTypes } from '../../../models/BlockArray/types'
import { useInitialEffect } from '../../../../hooks/useInitialEffect'
import { modifyActionParameter } from '../../../../store/actions/actionsActions'
import { ArrayCardsACTN } from './ArrayCardsACTN/ArrayCardsACTN'
import { ChipListBlockACTN } from './ChipListACTN/ChipListBlockACTN'

interface BlockArrayACTNProps {
    data: any[]
    schema: any
    dataPath: string[]
    title: string
    wrapper: string
    levelDeep?: number
    element_id?: string
}

export const BlockArrayACTN = (props: BlockArrayACTNProps) => {
    const {
        data,
        schema = {},
        title,
        dataPath,
        wrapper,
        levelDeep = 1,
        element_id,
    } = props

    const dispatch = useAppDispatch()
    const [path] = useState([...(dataPath || []), title])

    const schemaValues = Object.values(
        schema.children?.children || schema.children
    )
    const componentType = blockArrayTypeCheck(schemaValues, '')

    useInitialEffect(() => {
        if (data === null || data === undefined) {
            dispatch(
                modifyActionParameter({
                    path,
                    data: [],
                })
            )
        }
    })

    switch (componentType) {
        case BlockArrayTypes.TABLE:
            return <></>
        case BlockArrayTypes.MULTI_SELECT:
            return (
                <ChipListBlockACTN
                    data={data}
                    dataSchema={schema}
                    dataPath={dataPath}
                    name={title}
                    wrapper={wrapper}
                    levelDeep={levelDeep}
                    element_id={element_id}
                />
            )
        default:
            return (
                <ArrayCardsACTN
                    schema={schema}
                    data={data || []}
                    dataPath={path}
                    title={title}
                    wrapper={wrapper}
                    element_id={element_id}
                />
            )
    }
}
