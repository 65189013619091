import { ProductStatus, ProductType } from '../../../entity/Product'
import { FormattedMessage } from 'react-intl'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { showModal } from '../../../store/actions/modalActions'
import { ModalTypes } from '../../../types/modals'
import { changeProductStatus } from '../../../store/actions/products/productsActions'
import { getProductStatusValues } from '../../../utils/getProductStatusValues'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from '../Styles/index.module.scss'
import { withTooltip } from 't4b-core-frontend'

export interface IProductCardProductStatus {
    className?: string
    isCard?: boolean
    guid: string
    status: ProductStatus | undefined
    productType: ProductType | null | undefined
}

const ProductCardProductStatus = (props: IProductCardProductStatus) => {
    const { className, isCard, guid, status, productType } = props
    const dispatch = useAppDispatch()

    const newStatus =
        status === ProductStatus.ACTIVE
            ? ProductStatus.IDLE
            : ProductStatus.ACTIVE

    const confirmMsg =
        productType === ProductType.SERVICE ? (
            <FormattedMessage id="productCard.serviceProductChangeStatus" />
        ) : productType === ProductType.JOB ? (
            <FormattedMessage id="productCard.jobProductChangeStatus" />
        ) : (
            ''
        )

    const { statusName, statusStyle, icon, btnName } = getProductStatusValues(
        status,
        productType
    )

    const hideBtnInCard =
        (productType === ProductType.PLUGIN ||
            status === ProductStatus.INACTIVE) &&
        isCard

    const disabled =
        status?.includes('Changing') ||
        status === ProductStatus.INACTIVE ||
        productType === ProductType.PLUGIN

    const handleProductStatusChange = async () => {
        if (status === ProductStatus.ACTIVE) {
            dispatch(
                showModal(ModalTypes.MAIN_MODAL, {
                    body: confirmMsg,
                    onSubmit: () =>
                        dispatch(changeProductStatus(guid, newStatus)),
                })
            )
            return
        }
        dispatch(changeProductStatus(guid, newStatus))
    }

    const btn = withTooltip(
        <button
            onClick={handleProductStatusChange}
            disabled={disabled}
            className={`rounded-blue-btn ${disabled && styles.hoverless}`}
        >
            <FontAwesomeIcon
                color="white"
                icon={icon}
            />
        </button>,
        btnName || null,
        btnName
    )

    return (
        <div
            className={`d-flex gap-2 align-items-center ${
                isCard && styles.isCard
            } ${className}`}
        >
            <div>
                <span className="text-secondary pe-1">
                    <FormattedMessage id="productCard.status" />
                </span>
                <span>{statusName}</span>
                <span className={statusStyle} />
            </div>
            {hideBtnInCard ? <div className={styles.placeholder} /> : productType !== ProductType.PLUGIN && btn}
        </div>
    )
}

export default ProductCardProductStatus
