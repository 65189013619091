import React, { useState } from 'react'
import style from '../../../models/Block/Styles/index.module.scss'
import { isOdd } from '../../../../utils/math'
import { getLabel } from '../../../../validators'
import PromptBtn from '../../../buttons/PromptBtn/PromptBtn'
import { Collapse } from 'react-bootstrap'
import { blockConstructor } from '../../../models/Block/control'
import { generateAriaId } from '../../utils/generateAriaId'
import { generateUniqueLocalId } from '../../utils/generateUniqueLocalId'
import { useExpand } from '../../../../hooks/useExpand'

interface WrappedBlockACTNProps {
    elements?: JSX.Element[]
    title?: string
    className?: string
    data?: any
    dataSchema?: any
    name?: string
    wrapper?: string
    dataPath?: string[]
    buttons?: JSX.Element[]
    footerData?: JSX.Element[]
    headerControl?: any
    levelDeep?: number
    element_id?: string
}

export const WrappedBlockACTN = (props: WrappedBlockACTNProps) => {
    const {
        elements,
        title = '',
        className,
        data,
        dataSchema = {},
        name = '',
        wrapper,
        dataPath,
        buttons,
        footerData = [],
        headerControl = [],
        levelDeep = 2,
        element_id,
    } = props

    const {
        is_required: isRequired,
        is_auxiliary: isCollapsed,
        requiredMark,
    } = dataSchema

    const [path] = useState<string[]>([...(dataPath || []), name])

    const localId = generateUniqueLocalId({ path, name, element_id })
    const ariaID = generateAriaId()
    const collapseBtnClassName = style.button

    const { expandBtn, expanded, toggle} = useExpand({
        isExpanded: !isCollapsed,
        uniqueID: localId,
        ariaID,
        className: collapseBtnClassName,
    })

    const label = title ? title : getLabel(name)
    const styleHeader = `${style.header} block-header-${levelDeep} block-bg-header-default p-0 rounded-top`
    const styleBody = isOdd(levelDeep) ? 'bg-white' : 'app-bg-primary'

    const block = elements
        ? elements?.map((element: JSX.Element, i: number) => (
              <div key={i}>{element}</div>
          ))
        : blockConstructor(
              dataSchema,
              data,
              data || {},
              name,
              path,
              dataPath || [],
              true,
              1,
              element_id,
              undefined,
              true,
              undefined,
              'actionUiBuilder'
          )

    const desc = dataSchema.description && (
        <PromptBtn
            className="ms-auto me-2 text-white"
            size="small"
            prompt={dataSchema.description}
        />
    )

    return (
        <div
            className={`${className} ${
                !wrapper
                    ? 'width-49'
                    : 'w-100 border-left border-right border-bottom'
            } d-flex flex-column rounded mt-2 mb-2 position-relative border`}
        >
            <div className={styleHeader}>
                <div
                    className="w-100"
                    onClick={toggle}
                >
                    <h4 className={style.title}>
                        {wrapper === 'block_dictionary_card_row' ? (
                            <div style={{ height: '37px' }} />
                        ) : (
                            label
                        )}{' '}
                        {isRequired && requiredMark && <span>*</span>}
                    </h4>
                </div>
                {buttons &&
                    buttons.map((element: JSX.Element, index: number) => (
                        <span
                            key={index}
                            className="d-flex"
                        >
                            {element}
                        </span>
                    ))}
                {desc}
                {!!headerControl &&
                    headerControl.map((el: JSX.Element, i: number) => (
                        <span key={i}>{el}</span>
                    ))}
                {expandBtn}
            </div>
            <Collapse in={expanded}>
                <div className="p-relative">
                    <div
                        className={`p-2 ${styleBody} rounded-bottom`}
                        id={ariaID}
                    >
                        {block}
                    </div>
                    {footerData.length > 0 && (
                        <div
                            style={{ background: '#C5DBEA' }}
                            className="d-flex justify-content-end p-1"
                        >
                            {footerData.map((el: JSX.Element, i: number) => (
                                <div key={i}>{el}</div>
                            ))}
                            {expandBtn}
                        </div>
                    )}
                </div>
            </Collapse>
        </div>
    )
}
