export interface IBlockProps {
  elements?: JSX.Element[]
  title?: string
  className?: string
  classNameBody?: string
  data?: any
  dataSchema?: any
  name?: string
  wrapper?: string
  dataPath?: string[]
  buttons?: JSX.Element[]
  footerData?: JSX.Element[]
  headerControl?: any
  isOpened?: boolean | null | undefined
  isParentDisabled?: boolean
  levelDeep?: number
  isViewOnly?: boolean
  element_id?: string
  inCard?: boolean
  isRuleActive?: boolean
  pathWithElementID?: string[]
}

export enum BlockModels {
  ADJUSTABLE_RANGE = 'adjustable_range',
}
