import React, { FC, MouseEventHandler } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

interface IDeleteConfirmationPopover {
  onAction: MouseEventHandler<HTMLButtonElement>
  setState: React.Dispatch<React.SetStateAction<boolean>>
  message: string
  className?: string
}

export const ConfirmationPopover: FC<IDeleteConfirmationPopover> = ({
  onAction,
  setState,
  message,
  className,
}): JSX.Element => {
  const onCancelHandle = (): void => {
    setState(false)
  }

  return (
    <>
      <p className={`${className && className} h6 app-color-text`}>
        <FormattedMessage id={message} />
      </p>
      <div className="d-flex w-100">
        <Button
          variant="outline-primary"
          className="p-0 ps-1 pe-1"
          style={{ width: '45px' }}
          onClick={onAction}
        >
          <FormattedMessage id="yes" />
        </Button>
        <Button
          style={{ width: '45px', marginLeft: '4px' }}
          className="p-0 ps-1 pe-1 w-50"
          variant="outline-secondary"
          onClick={onCancelHandle}
        >
          <FormattedMessage id="no" />
        </Button>
      </div>
    </>
  )
}
