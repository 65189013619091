import { ModelTypes } from '../models/types'
import { JSX } from 'react'
import { StringACTN } from './actionModels/StringACTN'
import { BlockACTN } from './actionModels/BlockACTN/BlockACTN'
import { IntegerACTN } from './actionModels/IntegerACTN'
import { DoubleACTN } from './actionModels/DoubleACTN'
import { DurationACTN } from './actionModels/DurationACTN'
import { EnumACTN } from './actionModels/EnumACTN'
import { BoolACTN } from './actionModels/BoolACTN'
import { DateTimeACTN } from './actionModels/DateTimeACTN'
import { BlockArrayACTN } from './actionModels/BlockArrayACTN/BlockArrayACTN'
import { WrappedBlockACTN } from './actionModels/BlockACTN/WrappedBlockACTN'
import { BlockDictionaryACTN } from './actionModels/BlockDictionaryACTN/BlockDictionaryACTN'

export const buildActionsUI = (
    name: string,
    schema: any = {},
    data: any = {},
    wrapper?: string,
    path?: string[],
    isParentDisabled?: boolean,
    levelDeep?: number,
    element_id?: string,
    inCard?: boolean,
    isRuleActive?: boolean,
    pathWithElementID?: string[]
) => {
    const type: string = (schema.type as string) || 'default'

    const uiElements: { [key: string]: JSX.Element } = {
        [ModelTypes.BLOCK]: wrapper ? (
            <WrappedBlockACTN
                key={name + path}
                data={data?.[name]}
                dataSchema={schema}
                dataPath={path}
                title={schema?.name}
                name={name}
                wrapper={wrapper || 'block'}
                levelDeep={levelDeep}
                element_id={element_id}
            />
        ) : (
            <BlockACTN
                key={name + path}
                name={name}
                dataSchema={schema}
                data={data?.[name]}
                dataPath={path}
                title={schema?.name}
                wrapper={wrapper || ''}
                element_id={element_id}
                inCard={inCard}
            />
        ),
        [ModelTypes.BLOCK_ARRAY]: (
            <BlockArrayACTN
                key={name + path}
                schema={schema}
                data={data?.[name]}
                dataPath={path!}
                title={name}
                wrapper={wrapper || ''}
                levelDeep={levelDeep}
                element_id={element_id}
            />
        ),
        [ModelTypes.BLOCK_DICTIONARY]: (
            <BlockDictionaryACTN
                key={name + path}
                name={name}
                schema={schema}
                data={data?.[name]}
                dataPath={path!}
                wrapper={wrapper || ''}
            />
        ),
        [ModelTypes.STRING]: (
            <StringACTN
                key={name + path}
                name={name}
                dataSchema={schema}
                data={data}
                dataPath={path}
                wrapper={wrapper || ''}
            />
        ),
        [ModelTypes.INTEGER]: (
            <IntegerACTN
                key={name + path}
                name={name}
                dataSchema={schema}
                data={data}
                dataPath={path}
                wrapper={wrapper || ''}
            />
        ),
        [ModelTypes.DOUBLE]: (
            <DoubleACTN
                key={name + path}
                name={name}
                dataSchema={schema}
                data={data}
                dataPath={path!}
                wrapper={wrapper || ''}
            />
        ),
        [ModelTypes.NUMBER]: (
            <DoubleACTN
                key={name + path}
                name={name}
                dataSchema={schema}
                data={data}
                dataPath={path!}
                wrapper={wrapper || ''}
            />
        ),
        [ModelTypes.DURATION]: (
            <DurationACTN
                key={name + path}
                name={name}
                dataSchema={schema}
                data={data}
                dataPath={path!}
                wrapper={wrapper || ''}
            />
        ),
        [ModelTypes.ENUM]: (
            <EnumACTN
                key={name + path}
                name={name}
                dataSchema={schema}
                data={data}
                dataPath={path!}
                wrapper={wrapper || ''}
            />
        ),
        [ModelTypes.BOOL]: (
            <BoolACTN
                key={name + path}
                name={name}
                dataSchema={schema}
                data={data}
                dataPath={path!}
                wrapper={wrapper || ''}
            />
        ),
        [ModelTypes.FLAG]: (
            <BoolACTN
                key={name + path}
                name={name}
                dataSchema={schema}
                data={data}
                dataPath={path!}
                wrapper={wrapper || ''}
            />
        ),
        [ModelTypes.DATETIME]: (
            <DateTimeACTN
                key={name + path}
                name={name}
                dataSchema={schema}
                data={data}
                dataPath={path!}
            />
        ),
        [ModelTypes.TIME]: (
            <DateTimeACTN
                key={name + path}
                name={name}
                dataSchema={schema}
                data={data}
                dataPath={path!}
            />
        ),
        default: <></>,
    }

    return uiElements[type]
}
