import { ProductEntity } from '../../entity/Product'
import {
    IProduct,
    IProductGroup,
    IProductsReducer,
    ProductActionType,
} from '../../types/product'

const initialState: IProductsReducer = {
    products: [],
    quickAccess: [],
    productsGroups: [],
    isLoading: false,
    error: null,
    isProductRouted: false,
    productsIdDisplayNameDictionary: {},
    productLoading: '',
}

export const productsReducer = (
  state = initialState,
  action: { type: string; payload: any }
): IProductsReducer => {
  switch (action.type) {
    case ProductActionType.FETCH_PRODUCTS:
      return { ...state, isLoading: true }

    case ProductActionType.FETCH_PRODUCTS_SUCCESS:
      const products = action.payload.map(
          (product: IProduct) => new ProductEntity(product)
      )
      const productsIdDisplayNameDictionary = products.reduce(
          (acc: Record<any, any>, product: IProduct) => {
            acc[product.guid] = product.display_name
            return acc
          }, {})

      return {
        ...state,
        isLoading: false,
        products,
        productsIdDisplayNameDictionary,
      }
    case ProductActionType.FETCH_PRODUCTS_ERROR:
      return { ...state, isLoading: false, error: action.payload }

    case ProductActionType.FETCH_PRODUCTS_CANCEL:
      return { ...state, isLoading: false }

    case ProductActionType.DELETE_PRODUCT:
      return { ...state }

    case ProductActionType.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter(
          (product: IProduct) => product.guid !== action.payload?.guid
        ),
      }
    case ProductActionType.DELETE_PRODUCT_ERROR:
      return { ...state }

    case ProductActionType.SET_PRODUCT_ROUTED:
      return { ...state, isProductRouted: action.payload }

    case ProductActionType.RENAME_PRODUCT:
      return { ...state, productLoading: action.payload.guid }

    case ProductActionType.RENAME_PRODUCT_SUCCESS:
      return { ...state, productLoading: '' }

    case ProductActionType.RENAME_PRODUCT_ERROR:
      return { ...state, productLoading: '' }

    case ProductActionType.FETCH_QUICK_ACCESS:
      return {
        ...state,
        quickAccess: action.payload.products,
      }

    case ProductActionType.QUICK_ACCESS_ADD_ITEM:
      return {
        ...state,
        quickAccess: [...state.quickAccess, action.payload.guid],
      }

    case ProductActionType.QUICK_ACCESS_DND:
      return {
        ...state,
        quickAccess: state.quickAccess.filter(
          (guid: string) => guid !== action.payload.guid
        ),
      }

    case ProductActionType.FETCH_PRODUCTS_GROUPS:
      return {
        ...state,
        productsGroups: action.payload.productsGroups,
      }

    case ProductActionType.RENAME_PRODUCT_GROUP:
      return { ...state }

    case ProductActionType.RENAME_PRODUCT_GROUP_SUCCESS:
      return {
        ...state,
        productsGroups: state.productsGroups.map((group: IProductGroup) => {
          if (group.group_id === action.payload.grouId) {
            return { ...group, name: action.payload.name }
          }
          return group
        }),
      }
    case ProductActionType.RENAME_PRODUCT_GROUP_ERROR:
      return { ...state, error: action.payload }

    case ProductActionType.REORDER_PRODUCTS_GROUPS:
      return { ...state, productsGroups: action.payload }

    case ProductActionType.PRODUCT_DND_INSIDE_GROUP:
      const newProductsGroups = state.productsGroups.map(
          (productGroup) => {
            if (productGroup.group_id === action.payload.groupId){
              return { ...productGroup, entries: [ ...action.payload.data.entries ]  }
            }
            return productGroup
          })
      return { ...state, productsGroups: newProductsGroups }

    case ProductActionType.PRODUCT_DND_BETWEEN_GROUPS:
      return { ...state, productsGroups: action.payload }

    case ProductActionType.CHANGE_PRODUCT_STATUS: {
      const { productId, status } = action.payload
      const products = state.products.map((product) => {
        if (product.guid === productId) {
          return { ...product, status }
        }
        return product
      })

      return { ...state, products }
    }

    default:
      return state
  }
}
