import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useRef, useState } from 'react'
import style from '../Styles/index.module.scss'
import { IBlockDictionaryRow } from '../types'
import { AppTooltip } from '../../../overlays/AppTooltip'
import { AppPopover } from '../../../overlays/AppPopover'
import { ConfirmationPopover } from '../../../ConfirmationPopover'
import { blockDictionaryActions } from '../../../../store/actions/modelsActions/control'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { DictionaryValueEdit } from './DictionaryValueEdit'

// @prettier-ignore
const BlockDictionaryRow: FC<IBlockDictionaryRow> = ({
  data = {},
  dataSchema = {},
  name,
  dataPath,
  dictionaryKey,
  value,
  setDictKeys,
}) => {
  const dispatch = useAppDispatch()
  const ref = useRef(null)
  const [path] = useState([...(dataPath || []), name])
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [targetConfirmationModal, setTargetConfirmationModal] = useState(null)
  const [isEditing, setIsEditing] = useState(false)

  const hideEditForm = () => setIsEditing(false)
  const editOnDoubleClick = () => setIsEditing(true)

  const handleOnDelete = (event: any): void => {
    setShowConfirmationModal((prev) => !prev)
    setTargetConfirmationModal(event.target)
  }

  const onDeleteDiction = (): void => {
    dispatch(blockDictionaryActions.deleteItem(path, data, dictionaryKey))
    setShowConfirmationModal((prev) => !prev)
    setDictKeys((prev) => prev.filter((dict) => dict !== dictionaryKey))
  }

  const onBlurHandle = (): void => {
    setTimeout(() => setShowConfirmationModal(false), 100)
  }

  if (isEditing) return (
      <DictionaryValueEdit
          path={path}
          dictionaryKey={dictionaryKey}
          dictionaryValue={value}
          schema={dataSchema.children}
          hideEditForm={hideEditForm}
      />
  )

  return (
    <div
      className="border m-1 ps-2 pt-1 pb-1 bg-white rounded p-relative d-flex"
      onBlur={onBlurHandle}
    >
      <div style={{ wordBreak: 'break-word' }}>
        <AppTooltip
          text={dataSchema?.children?.key?.description}
          style={{ color: '#324766', fontWeight: 600 }}
          className="h6 p-1"
        >
          <span>{dictionaryKey ?? ''}&nbsp;:</span>
        </AppTooltip>
        <AppTooltip
          text={dataSchema?.children?.value?.description}
          style={{ color: '#324766', fontWeight: 600 }}
          className="h6 text-success p-1"
        >
          <span onDoubleClick={editOnDoubleClick}>{String(value)}</span>
        </AppTooltip>
      </div>

      <div ref={ref}>
        <button
          onClick={(event) => handleOnDelete(event)}
          type="button"
          className={style.button}
        >
          <span>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        </button>
        <AppPopover
          show={showConfirmationModal}
          target={targetConfirmationModal}
          placement="top"
          container={ref}
          className="d-flex p-1 flex-column align-items-center"
          style={{ width: '110px' }}
        >
          <ConfirmationPopover
            onAction={onDeleteDiction}
            setState={setShowConfirmationModal}
            message="Delete item?"
          />
        </AppPopover>
      </div>
    </div>
  )
}

export default BlockDictionaryRow
