import * as signalR from '@microsoft/signalr'
import { getJwt } from '../utils/authentication'

export const createSignalRConnection = () =>
    new signalR.HubConnectionBuilder()
        .withUrl('/signalr', {
            accessTokenFactory: () => getJwt() ?? '',
            withCredentials: true,
            transport:
                process.env.NODE_ENV === 'production'
                    ? undefined
                    : signalR.HttpTransportType.WebSockets,
        })
        .configureLogging(signalR.LogLevel.Information)
        .withAutomaticReconnect()
        .build()
