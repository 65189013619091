import React, { createRef, FC, useEffect, useState } from 'react'
import style from '../Styles/index.module.scss'
import { IProductGroup } from '../../../types/product'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { Options } from 'overlayscrollbars'
import { ProductsDropdownItemGroup } from './ProductsDropdownItemGroup'
import { getOtherProducts } from '../../../utils/filters'

interface IProductsDropdownList {
    isPopover?: boolean
}

const ProductsDropdownList: FC<IProductsDropdownList> = ({
  isPopover,
}): JSX.Element => {
  const { products, isProductRouted, productsGroups, quickAccess } =
    useTypedSelector((state) => state.products)
  const dropdownBorderRef: any = createRef()
  const [dropdownBorderHeight, setDropdownBorderHeight] = useState('0')
  const maxHeight = 400
  const targetBorderHeight = maxHeight - 40
  const othersProductsList = getOtherProducts(productsGroups, products)

  useEffect(() => {
    const height = dropdownBorderRef?.current?.getBoundingClientRect()?.height
    setDropdownBorderHeight(height)
  }, [dropdownBorderRef])

  return (
    <OverlayScrollbarsComponent
      style={{
        maxHeight: `${maxHeight}px`,
        width: '100%',
        borderRadius: isPopover ? '8px' : '0',
      }}
      className={`${!isPopover && 'sidebar-scroll'}`}
      options={
        {
          overflowBehavior: {
            x: 'visible-hidden',
          },
        } as Options
      }
    >
      <div
        style={{ background: isProductRouted ? '#397B8E' : 'transparent' }}
        className={
          !isPopover ? style.dropdownContainer : style.dropdownContainerPopover
        }
      >
        {!isPopover && (
          <div
            ref={dropdownBorderRef}
            className={`${style.dropdownBorder} 
            ${
              Number(dropdownBorderHeight) > targetBorderHeight &&
              'app-bg-transparent'
            }
            `}
          />
        )}

        <div className="d-flex flex-column w-100">
          {quickAccess.length ? (
            <div className="w-100">
              <ProductsDropdownItemGroup
                isPopover={isPopover}
                productsList={products}
                entries={quickAccess}
                name="Quick access"
              />
            </div>
          ) : (
            <></>
          )}

          {productsGroups.map((groupData: IProductGroup) => (
            <div className="w-100" key={groupData.group_id}>
              <ProductsDropdownItemGroup
                isPopover={isPopover}
                productsList={products}
                entries={groupData.entries}
                name={groupData.name}
              />
            </div>
          ))}

          {othersProductsList.length ? (
            <div className="w-100 mt-3">
              <ProductsDropdownItemGroup
                isPopover={isPopover}
                productsList={products}
                entries={othersProductsList.map((el) => el.guid)}
                name="Other products"
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </OverlayScrollbarsComponent>
  )
}

export default ProductsDropdownList
