import { StoreSchema } from '../../types/store'
import { createSelector } from 'reselect'
import { SystemActionType } from '../../types/actionsTypes'

export const selectSystemActions = (store: StoreSchema) =>
    store.actions.systemActions

export const selectProductSystemActions = (guid: string) =>
    createSelector(
        [selectSystemActions],
        (systemAction) => systemAction[guid]
    )

export const selectOneSystemAction = (guid: string, actionName: SystemActionType) =>
    createSelector(
        [selectSystemActions],
        (systemAction) => systemAction[guid]?.[actionName]
    )