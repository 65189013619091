import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark'
import classes from './ChipList.module.scss'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'
import { checkOverflow } from '../../../../../utils/checkOverflow'
import { getValidationSchema } from '../../utils/getValidationSchema'
import { useFormValidation } from '../../../../../hooks/useFormValidation'
import { inputCall } from '../../../control'
import { buildControlsExt } from '../../../../inputs/controls'

interface ChipListProps {
    name: string
    schema: any
    state: any
    setState: Function
}

export const ChipList = (props: ChipListProps) => {
    const { name, schema, state, setState } = props

    const [viewMore, setViewMore] = useState(false)
    const [showViewMore, setShowViewMore] = useState(false)
    const chipsContainerRef = useRef<HTMLDivElement>(null)
    const items = state?.[name] // data for chips

    const newRowData = { value: '' }
    const validationSchema = getValidationSchema({ value: schema.children })
    const [inputState, setInputState, touched, setTouched, validationResult] =
        useFormValidation(newRowData, validationSchema)

    const isErrorKeyPresent = Object.keys(validationResult).length
    const isNewRowValid = inputState.value?.length && !isErrorKeyPresent

    const handleAdd = () => {
        if (!isNewRowValid) return

        const isValueIncluded = state?.[name]
            ?.map((el: { label: string; value: string }) => el.value)
            .includes(inputState.value)

        if (!isValueIncluded) {
            setState({
                [name]: [
                    ...state[name],
                    { ...inputState, label: inputState.value },
                ],
            })

            if (!viewMore) {
                setViewMore(true)
            }
        }

        setInputState(newRowData) // clear input
        setTouched((prevTouched: typeof touched) => {
            return { ...prevTouched, value: false }
        })
    }

    const handleBlur = () => {
        if (inputState.value === '' || inputState.value === null) {
            setTouched((prevTouched: typeof touched) => {
                return { ...prevTouched, value: false }
            })
        }
    }

    const input = inputCall({
        type: schema.children.type,
        name: 'value',
        isLabelHidden: true,
        data: inputState,
        schema: schema.children,
        state: inputState,
        onEnter: handleAdd,
        onBlur: handleBlur,
    })

    const element = buildControlsExt(
        [input],
        inputState,
        setInputState,
        '',
        touched,
        setTouched,
        validationResult
    )

    const handleDeleteItem = (item: any) => {
        setState({
            [name]: state[name].filter((el: any) => el !== item),
        })
    }

    const handleViewMore = () => {
        setViewMore((prev) => !prev)
    }

    useEffect(() => {
        if (chipsContainerRef.current) {
            const hasOverflowItems = checkOverflow(chipsContainerRef.current)
            const isOverMH = chipsContainerRef.current?.scrollHeight > 72
            setShowViewMore(hasOverflowItems || isOverMH)
        }
    }, [state])

    return (
        <div className="d-flex flex-column gap-2 px-1 py-1">
            <div className={`d-flex gap-2 ${classes.formBlock}`}>
                <div className="d-flex flex-column flex-grow-1">{element}</div>
                <div className={classes.addButtons}>
                    <button
                        className="app-btn-add"
                        onClick={handleAdd}
                        disabled={!isNewRowValid}
                    >
                        Add
                    </button>
                </div>
            </div>

            {!!items && !!items.length && (
                <>
                    <div
                        ref={chipsContainerRef}
                        className={`${classes.chipsContainer} ${
                            viewMore ? '' : classes.maxHeight
                        }`}
                    >
                        {items.map((item: any, index: number) => (
                            <span
                                key={index.toString()}
                                className={`${classes.chip}`}
                            >
                                {item.value}&nbsp;
                                <button
                                    className={classes.deleteBtn}
                                    onClick={() => handleDeleteItem(item)}
                                >
                                    <FontAwesomeIcon icon={faXmark} />
                                </button>
                            </span>
                        ))}
                    </div>
                    {showViewMore && (
                        <button
                            onClick={handleViewMore}
                            className={classes.viewMore}
                        >
                            {viewMore ? (
                                <>
                                    <FormattedMessage id="buttons.viewLess" />
                                    <span className="ms-2">
                                        <FontAwesomeIcon icon={faAngleUp} />
                                    </span>
                                </>
                            ) : (
                                <>
                                    <FormattedMessage id="buttons.viewMore" />
                                    <span className="ms-2">
                                        <FontAwesomeIcon icon={faAngleDown} />
                                    </span>
                                </>
                            )}
                        </button>
                    )}
                </>
            )}
        </div>
    )
}
