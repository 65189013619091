import React, { memo } from 'react'
import { buildActionsUI } from '../buildActionsUI'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { selectOneCustomAction } from '../selectors/actionsSelectors'
import { selectProductActionsParameters } from '../selectors/actionsParametersSelectors'

interface ActionParametersProps {
    guid: string
    actionName: string
}

export const ActionParameters = memo(function ActionParameters({
    guid,
    actionName,
}: ActionParametersProps) {
    const currentAction = useTypedSelector(selectOneCustomAction(guid, actionName))
    const productActionsParameters = useTypedSelector(
        selectProductActionsParameters(guid)
    )
    const actionSchema = currentAction?.request_schema

    const actionComponent = buildActionsUI(
        actionName,
        actionSchema,
        productActionsParameters,
        '',
        [guid]
    )

    return (
        <>
            <h5>Parameters</h5>
            <div className="d-flex flex-column gap-2">{actionComponent}</div>
        </>
    )
})
