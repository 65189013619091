import { ProductStatus, ProductType } from '../entity/Product'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { faPause } from '@fortawesome/free-solid-svg-icons/faPause'
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay'
import { faCircleStop } from '@fortawesome/free-regular-svg-icons/faCircleStop'

interface ProductStatusValues {
    statusName:
        | ProductStatus
        | 'On standby'
        | 'Changing to standby'
        | 'Changing to active'
    statusStyle: 'p-active' | 'p-inactive' | 'p-idle' | 'p-changing'
    btnName: 'Start' | 'Stop' | 'Resume' | 'Pause' | ''
    icon: IconDefinition
}

export const getProductStatusValues = (
    productStatus: ProductStatus | undefined,
    productType: ProductType| null | undefined
) => {

    const productStatusValues: Record<ProductStatus, ProductStatusValues> = {
        [ProductStatus.ACTIVE]: {
            statusName: ProductStatus.ACTIVE,
            statusStyle: 'p-active',
            icon: productType === ProductType.JOB ? faCircleStop: faPause  ,
            btnName: productType === ProductType.SERVICE
                ? 'Pause' : productType === ProductType.JOB ? 'Stop' : ''
        },
        [ProductStatus.IDLE]: {
            statusName: 'On standby',
            statusStyle: 'p-idle',
            icon: faPlay,
            btnName: productType === ProductType.SERVICE
                ? 'Resume' : productType === ProductType.JOB ? 'Start' : ''
        },
        [ProductStatus.CHANGING_TO_IDLE]: {
            statusName: 'Changing to standby',
            statusStyle: 'p-changing',
            icon: productType === ProductType.JOB? faCircleStop : faPause,
            btnName: productType === ProductType.SERVICE
                ? 'Pause' : productType === ProductType.JOB ? 'Stop' : ''
        },
        [ProductStatus.CHANGING_TO_ACTIVE]: {
            statusName: 'Changing to active',
            statusStyle: 'p-changing',
            icon: faPlay,
            btnName: productType === ProductType.SERVICE
                ? 'Resume' : productType === ProductType.JOB ? 'Start' : ''
        },
        [ProductStatus.INACTIVE]: {
            statusName: ProductStatus.INACTIVE,
            statusStyle: 'p-inactive',
            icon: faPlay,
            btnName: productType === ProductType.SERVICE
                ? 'Resume' : productType === ProductType.JOB ? 'Start' : ''
        },
    }

    return productStatusValues[productStatus ?? ProductStatus.INACTIVE]
}
