import React, { FC, useState } from 'react'
import { IProduct } from '../../../types/product'
import ProductsDropdownItem from './ProductsDropdownItem'
import style from '../Styles/index.module.scss'
import { Collapse } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faAngleDown,
    faAngleUp,
    faBookmark,
} from '@fortawesome/free-solid-svg-icons'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { NoData } from '../../NoData'

interface IProductsDropdownItemGroup {
    productsList: IProduct[]
    isPopover?: boolean
    entries: string[]
    name: string
}

export const ProductsDropdownItemGroup: FC<IProductsDropdownItemGroup> = ({
  productsList,
  isPopover,
  entries,
  name,
}): JSX.Element => {
  const { productsGroups, quickAccess } = useTypedSelector(
    (state) => state.products
  )

  const [open, setOpen] = useState(true)
  const openCloseHandle = () => setOpen(!open)
  const products = productsList.reduce(
      (acc: IProduct[], el: IProduct, i: number, arr) => {
        const item = arr.find((el) => el.guid === entries[i])
        if (item) {
          acc[i] = item;
        }
        return acc
      }, [])

  return (
    <div className={isPopover ? 'border-bottom w-100 pb-2' : ''}>
      {productsGroups.length || quickAccess.length ? (
        <button
          className={`${style.dropdownGroupTitle} ${
            isPopover ? 'ps-3 pt-2' : ''
          }`}
          onClick={openCloseHandle}
          aria-controls="example-collapse-text"
          aria-expanded={open}
        >
          {name === 'Quick access' ? (
            <FontAwesomeIcon className="me-2" icon={faBookmark} size="sm" />
          ) : (
            <></>
          )}
          {name}
          <FontAwesomeIcon
            size="xs"
            className="ms-3"
            icon={open ? faAngleUp : faAngleDown}
          />
        </button>
      ) : (
        <div className="pt-2" />
      )}
      <Collapse in={open}>
        <div id="example-collapse-text">
          {!products.length ? (
            <NoData
              type="dropdown"
              isPopover={isPopover}
              style={{ height: '50px', fontSize: '15px' }}
              messageId="summary.noProductsInGroup"
            />
          ) : (
            products.map((product: IProduct) => (
              <div key={product.guid}>
                <div className={style.dropdownProduct}>
                  <ProductsDropdownItem
                    isPopover={isPopover}
                    product={product}
                  />
                </div>
              </div>
            ))
          )}
        </div>
      </Collapse>
    </div>
  )
}
