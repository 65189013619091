import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { buildControlsExt, selectInput } from '../../inputs/controls'
import { IEnumProps } from '../../models/Enum/types'
import { getInputLabel } from '../utils/getInputLabel'
import { modifyActionParameter } from '../../../store/actions/actionsActions'
import { useDebouncedValue } from '../../../hooks/useActions/useDebouncedValue'

export const EnumACTN = (props: IEnumProps) => {
    const { data = {}, dataSchema = {}, name, wrapper, dataPath } = props
    const dispatch = useAppDispatch()

    const {
        name: inputLabel,
        description: tooltipText,
        default: defaultValue,
        options,
    } = dataSchema

    const optionKeys = useRef(Object.keys(options ?? {}))
    const [path] = useState([...(dataPath || []), name])
    const [inputState, setInputState, touched, setTouched] = useFormValidation(
        data,
        {}
    )
    const debouncedInputState = useDebouncedValue(inputState, 500)

    const label = getInputLabel(name, inputLabel)
    const className = !wrapper ? 'w-100 pe-2' : ''

    useEffect(() => {
        dispatch(
            modifyActionParameter({
                path,
                data: inputState,
            })
        )
    }, [debouncedInputState])

    const EnumInput = buildControlsExt(
        [
            selectInput({
                name,
                options: Object.keys(options),
                selected: data?.[name] || defaultValue || optionKeys.current[0],
                tooltipText,
                label,
                className,
                isColumn: true,
            }),
        ],
        inputState,
        setInputState,
        '',
        touched,
        setTouched
    )

    return <div className={`${!wrapper ? 'w-100' : ''}`}>{EnumInput}</div>
}
