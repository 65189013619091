import { StoreSchema } from '../../../types/store'
import { createSelector } from 'reselect'

export const selectActionsParameters = (store: StoreSchema) =>
    store.actions.actionsParameters

export const selectProductActionsParameters = (guid: string) =>
    createSelector(
        [selectActionsParameters],
        (actionsParameters) => actionsParameters[guid]
    )

export const selectActionParams = (guid: string, actionName: string) =>
    createSelector(
        [selectActionsParameters],
        (actionsParameters) => actionsParameters[guid]?.[actionName]
    )
