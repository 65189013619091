import importExport from '../../../assets/icons/importExport.svg'
import { BDropdown } from '../BDropdown/BDropdown'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { showModal } from '../../../store/actions/modalActions'
import { ModalTypes } from '../../../types/modals'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons/faFileUpload'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { selectProductSystemActions } from '../../../store/selectors/systemActionsSelectors'
import { AppDropdownItem } from '../AppDropdown/AppDropdown'

interface UploadDownloadConfigProps {
    className?: string
    guid: string
}

export const UploadDownloadConfig = (props: UploadDownloadConfigProps) => {
    const { guid, className } = props
    const dispatch = useAppDispatch()

    const productSystemActions =
        useTypedSelector(selectProductSystemActions(guid)) ?? {}

    const {
        get_configuration_file: hasGetConfigFile,
        set_configuration_file: hasSetConfigFile,
    } = productSystemActions

    const handleUpload = () => {
        dispatch(
            showModal(ModalTypes.UPLOAD_CONFIGS_MODAL, {
                guid,
            })
        )
    }
    const handleDownload = () => {
        dispatch(
            showModal(ModalTypes.CONFIGS_MODAL, {
                guid,
            })
        )
    }

    const uploadConfig: AppDropdownItem = {
        name: 'buttons.uploadConfig',
        icon: faFileUpload,
        action: handleUpload,
        disabled: !hasSetConfigFile,
    }
    const downloadConfig: AppDropdownItem = {
        name: 'buttons.downloadConfig',
        icon: faFileDownload,
        action: handleDownload,
        disabled: !hasGetConfigFile,
    }

    const uploadDownloadConfigItems = [uploadConfig, downloadConfig]

    return (
        <BDropdown
            className={`rounded-blue-btn ${className}`}
            tooltip="Upload / Download config"
            dropdownItems={uploadDownloadConfigItems}
            dropdownToggle={
                <img
                    alt=""
                    className="pb-1"
                    src={importExport}
                />
            }
        />
    )
}
