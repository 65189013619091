import { useEffect, useRef } from 'react'

export const useEffectAfterMount = (
    callback: Function,
    dependencies: any[]
) => {
    const hasMounted = useRef(false)

    useEffect(() => {
        if (hasMounted.current) {
            callback()
        } else {
            hasMounted.current = true
        }
    }, dependencies) //eslint-disable-line
}
