import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useLocalStorage } from './useLocalStorage'
import { ButtonWithTooltip } from '../components/buttons/ButtonWithTooltip'

export const useExpand = ({
    isExpanded,
    uniqueID,
    ariaID,
    className,
}: {
    isExpanded: boolean
    uniqueID: string
    ariaID: string
    className: string
}) => {
    const [expanded, setExpanded] = useLocalStorage(uniqueID, isExpanded)

    const expand = () => setExpanded(true)
    const collapse = () => setExpanded(false)
    const toggle = (e: any) => {
        e.stopPropagation()
        setExpanded((prev) => !prev)
    }

    const tooltip = expanded ? 'Collapse' : 'Expand'

    const expandBtn = (
        <ButtonWithTooltip tooltip={tooltip}>
            <button
                onClick={toggle}
                className={className}
                aria-controls={ariaID}
                aria-expanded={expanded}
            >
                <FontAwesomeIcon icon={expanded ? faAngleUp : faAngleDown} />
            </button>
        </ButtonWithTooltip>
    )

    return { expandBtn, expanded, expand, collapse, toggle }
}
