import { BlockArrayTypes } from '../types'

export const blockArrayTypeCheck = (
    data: any,
    model: string,
    tableView?: boolean
) => {
    let componentType: string = BlockArrayTypes.TABLE

    const isTableView = model === 'rule' && tableView
    const isSummary = isTableView
        ? data.some((el: any) => el.hasOwnProperty('is_summary'))
        : false

    if (isSummary) {
        componentType = BlockArrayTypes.TABLE_VIEW
        return componentType
    }

    for (let item of data) {
        if (item.children) {
            componentType = BlockArrayTypes.CARD
            break
        }
        if (item && typeof item !== 'object') {
            componentType = BlockArrayTypes.MULTI_SELECT
            break
        }
    }

    return componentType
}
