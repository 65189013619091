import { ProductStatus } from '../../../entity/Product'
import { IProduct, IProductGroup } from '../../../types/product'
import { DropResult } from 'react-beautiful-dnd'

export const getProductsList = (products: IProduct[], showActive: boolean) => {
    return showActive
        ? products.filter(
              (product: IProduct) => product.status !== ProductStatus.INACTIVE
          )
        : products
}

export const reorder = (
    list: any[],
    startIndex: number,
    endIndex: number
) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

export const reorderBetweenGroups = (
    productGroups: IProductGroup[],
    result: DropResult | any
): IProductGroup[] => {
    const sourceGroup: any = productGroups.find(
            (group) => group.group_id === result?.source?.droppableId
        )
    const reorderSourceGroup = { ...sourceGroup, entries: [ ...sourceGroup.entries ] }
    const [dndProduct]: any = reorderSourceGroup.entries?.splice(result?.source?.index, 1)

    const destinationGroup: any = productGroups.find(
            (group) => group.group_id === result?.destination?.droppableId
        )
    const reorderedDestinationGroup = { ...destinationGroup, entries: [ ...destinationGroup.entries ] }
    reorderedDestinationGroup.entries.splice(result?.destination?.index, 0, dndProduct)

    const reorderedGroups: IProductGroup[] = productGroups.map(group => {
        if (group.group_id === result.source.droppableId) {
            return reorderSourceGroup
        }
        if (group.group_id === result.destination.droppableId) {
            return reorderedDestinationGroup
        }
        return { ...group, entries: [...group.entries] }
    })
    return reorderedGroups
}

export const getGroupEditableFormat = (
    group: IProductGroup | undefined,
    productIds: string[],
    groupIds: string[]
) => {
    return {
        name: group?.name ?? '',
        entries: productIds,
        groupsList: groupIds,
    }
}

export const getGroupProducts = (productsList: IProduct[], groupData: IProductGroup) =>
    productsList.reduce((acc: IProduct[], el: IProduct, i: number, arr) => {
        const item = arr.find((el) => el.guid === groupData?.entries[i])
        if (item) {
            acc[i] = item
        }
        return acc
    }, [])