import { LogsActions } from '../types/logs'
import { HubConnection } from '@microsoft/signalr'
import { AppDispatch } from '../types/store'

export const receiveLiveLogs = (
    connection: HubConnection,
    dispatch: AppDispatch
) => {
    connection.on('log_batch', (logsBatch, productGuid) => {
        if (productGuid && logsBatch && logsBatch['log_batch']) {
            dispatch({
                type: LogsActions.NEW_LIVE_LOGS,
                payload: { productGuid, liveLogs: logsBatch['log_batch'] },
            })
        }
    })
}