import { IActionHistory } from '../../../types/actionsTypes'
import styles from './ActionHistoryRow.module.scss'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileZipper } from '@fortawesome/free-regular-svg-icons'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { downloadActionResult } from '../../../store/actions/actionsActions'
import { convertToSentenceCase } from '../../../utils/convertToSentenceCase'

interface ActionHistoryRowProps {
    className?: string
    actionHistoryItem: IActionHistory
}

const userLocale = navigator.language ?? 'en-US'

export const ActionHistoryRow = (props: ActionHistoryRowProps) => {
    const dispatch = useAppDispatch()
    const { actionHistoryItem } = props
    const {
        result_type: resultType,
        action_id: actionId,
        action_name: actionName,
        issue_time: issueTime,
        status,
        error,
        result,
    } = actionHistoryItem

    const filename = result?.filename
    const issueTimeLocal = new Date(issueTime).toLocaleString(userLocale)
    const actionStatus = capitalizeFirstLetter(actionHistoryItem.status)
    const actionNameModified = convertToSentenceCase(actionName)
    const handleFileDownload = () => dispatch(downloadActionResult(actionId))

    return (
        <tr
            key={actionId}
            className={styles.tableRow}
        >
            <td className={styles.tableCell}>{issueTimeLocal}</td>
            <td className={styles.tableCell}>{actionNameModified}</td>
            <td className={`${styles.tableCell} ${styles[status]}`}>
                {actionStatus}
            </td>
            <td className={styles.tableCell}>
                {resultType === 'file' && !!filename && (
                    <span className="d-flex align-items-center">
                        <button
                            onClick={handleFileDownload}
                            className={`${styles.downloadBtn} btn-clean d-flex align-items-center gap-1`}
                        >
                            <FontAwesomeIcon icon={faFileZipper} />
                            <span className="d-inline">{filename}</span>
                        </button>
                    </span>
                )}
                {status === 'failure' && !!error && <span>{error}</span>}
            </td>
        </tr>
    )
}
