
export enum NotificationsActions {
    FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS',
    FETCH_NOTIFICATIONS_ONSCROLL = 'FETCH_NOTIFICATIONS_ONSCROLL',
    NEW_NOTIFICATIONS = 'NEW_NOTIFICATIONS',
    MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ',
    MARK_NOTIFICATION = 'MARK_NOTIFICATION',
}

export enum NotificationStatus {
    READ = 'read',
    UNREAD = 'unread'
}

export interface INotification {
    notification_id: string
    product_id: string
    created_at: string // ISOString
    status: NotificationStatus
    message: string
}

export interface INotificationReducer {
    notifications: INotification[]
    hasMore: boolean
    isLoading: boolean
}
