import { useAppDispatch } from '../../../../../hooks/useAppDispatch'
import React, { useEffect, useState } from 'react'
import style from '../../../../models/BlockArray/Styles/index.module.scss' // styles from CardsContainer
import { getInputLabel } from '../../../utils/getInputLabel'
import { generateAriaId } from '../../../utils/generateAriaId'
import { generateUniqueLocalId } from '../../../utils/generateUniqueLocalId'
import { getBlockFooterData } from '../../../../../utils/blockFooterData'
import PromptBtn from '../../../../buttons/PromptBtn/PromptBtn'
import { Collapse } from 'react-bootstrap'
import { CardACTN } from './CardACTN'
import AppButton from '../../../../buttons/AppButton/AppButton'
import {
    addCardItemToArrayBeginning,
    addCardItemToArrayEnd,
    setActionInvalid,
    setActionValid,
} from '../../../../../store/actions/actionsActions'
import { getEmptyCard } from '../../../../models/BlockArray/utils/getEmptyCard'
import { useExpand } from '../../../../../hooks/useExpand'
import { NoData } from '../../../../NoData'

interface ArrayCardsACTNProps {
    schema: any
    data: any[]
    dataPath: string[]
    title: string
    wrapper?: string
    element_id?: string
}

export const ArrayCardsACTN = (props: ArrayCardsACTNProps) => {
    const { data, schema, dataPath, title, wrapper, element_id } = props
    const {
        is_required: isRequired,
        is_empty_as_null: isEmptyAsNull,
        is_auxiliary: isCollapsed,
        description,
    } = schema

    const dispatch = useAppDispatch()
    const [path] = useState([...(dataPath || [])])

    const localId = generateUniqueLocalId({ path, name: title, element_id })
    const ariaID = generateAriaId()
    const collapseBtnClassName = style.button

    const { expandBtn, expanded, expand, toggle } = useExpand({
        isExpanded: !isCollapsed,
        ariaID,
        uniqueID: localId,
        className: collapseBtnClassName,
    })

    const dataLen = data.length
    const label = getInputLabel(title, schema.name)
    const styleHeader = `${style.header} app-bg-navi-blue rounded border p-0`

    const componentSchema = schema.children?.children || schema.children
    const schemaKeys = Object.keys(componentSchema)
    const emptyCardData = getEmptyCard(schemaKeys, componentSchema)

    const addCardToArrayBeginning = () => {
        dispatch(addCardItemToArrayBeginning({ path, data: emptyCardData }))
        expand()
    }

    const addCardToArrayEnd = () => {
        dispatch(addCardItemToArrayEnd({ path, data: emptyCardData }))
        expand()
    }

    const footerData = getBlockFooterData(
        data,
        0,
        addCardToArrayEnd,
        'tooltips.addToEnd'
    )

    useEffect(() => {
        if (isRequired && isEmptyAsNull && !dataLen) {
            dispatch(setActionInvalid({ path }))
        }
        if (isRequired && dataLen) {
            dispatch(setActionValid({ path }))
        }
    }, [dataLen])

    const addBtn = (
        <AppButton
            variant="add"
            onClick={addCardToArrayBeginning}
            className="btn-success btn btn-add p-1 mt-1 me-3 mb-1 ms-auto ps-3 pe-3"
            tooltip="tooltips.addToBeginning"
        />
    )
    const requiredMark = isRequired && isEmptyAsNull && !dataLen && (
        <PromptBtn
            className="me-2"
            type="validation"
            prompt="At least 1 item required"
        />
    )
    const desc = description && (
        <PromptBtn
            className="ms-auto me-2 text-white"
            size="small"
            prompt={description}
        />
    )

    return (
        <div
            className={` w-100 border-left border-right border-bottom d-flex flex-column rounded mt-2 mb-2 position-relative ${style.cardsContainer}`}
        >
            <div className={styleHeader}>
                <div
                    className="w-100"
                    onClick={toggle}
                >
                    <h4 className={style.title}>{label}</h4>
                </div>
                {requiredMark}
                {addBtn}
                {desc}
                {expandBtn}
            </div>
            <Collapse in={expanded}>
                <div
                    className={`pt-2 app-bg-primary flex-`}
                    id={ariaID}
                >
                    {dataLen === 0 ? (
                        <NoData
                            style={{ height: '100px' }}
                            className="w-100 p-relative d-flex align-items-center justify-content-center text-secondary"
                        />
                    ) : (
                        <div className="w-100 p-relative">
                            <div className={!wrapper ? '' : style.cardsColumn}>
                                {data.map((el: any, index: number) => (
                                    <div className="hover flex-column mw-350 rounded bg-white justify-content-between p-relative d-flex m-1 border">
                                        <CardACTN
                                            config={el}
                                            schema={schema}
                                            dataPath={path}
                                            index={index}
                                            element_id={el.element_id}
                                            position={index + 1}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {!!footerData?.length && (
                        <div
                            style={{ background: '#C5DBEA' }}
                            className="d-flex p-1 justify-content-end"
                        >
                            {footerData}
                            {expandBtn}
                        </div>
                    )}
                </div>
            </Collapse>
        </div>
    )
}
