import React from 'react'
import { FormattedMessage } from 'react-intl'
import { toast } from 'react-toastify'

const bottomRightToastPosition = { position: toast.POSITION.BOTTOM_RIGHT }

export const throwErrorMessageWithoutAutoClose = (message: string) => {
  if (message) {
    toast.error(<div className="error-toast">{message}</div>, {
      autoClose: false,
      closeOnClick: false,
    })
    return
  }
  toast.error(
    <FormattedMessage id={'errors.server.serverError'} tagName="span" />
  )
}

export const throwErrorMessage = (
  message: string,
  status?: number | string
): void => {
  if (status === 503 || status === 401) {
    return
  }

  if (message) {
    toast.error(<div className="error-toast">{message}</div>, {
      closeOnClick: false,
    })
    return
  }
  if (status === 400 && !message) {
    toast.error(<FormattedMessage id="errors.server.400" tagName="span" />)
    return
  }
  if (status === 403 && !message) {
    toast.error(<FormattedMessage id="errors.server.403" tagName="span" />)
    return
  }
  if (status === 404 && !message) {
    toast.error(<FormattedMessage id="errors.server.404" tagName="span" />)
    return
  }
  if (status === 409 && !message) {
    toast.error(<FormattedMessage id="errors.server.409" tagName="span" />)
    return
  }
  if (status === 429 && !message) {
    toast.error(<FormattedMessage id="errors.server.429" tagName="span" />)
    return
  }
  if (status === 500 && !message) {
    toast.error(<FormattedMessage id="errors.server.500" tagName="span" />)
    return
  }
  if (status === 502 && !message) {
    toast.error(<FormattedMessage id="errors.server.502" tagName="span" />)
    return
  }
  toast.error(
    <FormattedMessage id={'errors.server.serverError'} tagName="span" />
  )
}

export const throwSuccessMessage = (message: any): void => {
  toast.success(
    <span className="word-break">{message}</span>,
    bottomRightToastPosition
  )
}

export const alertDetailedErrorMessage = (detail: string) => {
  if (detail) {
    toast.error(<div className="error-toast">{detail}</div>, {
      closeOnClick: false,
    })
  } else {
    throwErrorMessage('Unexpected error. Error is of type unidentified. Please, check network and server availability')
  }
}

export const alertErrorMessage = (e: any) => {
  console.log(e)
  if (e?.response?.status === 0 || e?.code === 'ERR_NETWORK') {
    if (navigator && navigator.onLine) {
      throwErrorMessage(
          'Server connection failure. Server is unavailable.'
      )
    } else {
      throwErrorMessage('Please, check your Internet connection!')
    }
    return
  }
  if (e?.response?.data?.errors && Object.values(e?.response?.data?.errors).length) {
      // @ts-ignore
    const detailedError = Object.values(e?.response?.data?.errors)?.[0]?.[0]
      alertDetailedErrorMessage(detailedError)
  }
  else{
    const unexpectedError = e?.response?.status === 500 ? 'Internal server error' : e?.response?.status
    alertDetailedErrorMessage(
        e?.response?.data?.detail || e?.response?.statusText || unexpectedError
    )
  }
}
