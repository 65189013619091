import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useFormValidation } from '../../../hooks/useFormValidation'
import React, { useEffect, useState } from 'react'
import { buildControlsExt, checkboxInput } from '../../inputs/controls'
import { getInputLabel } from '../utils/getInputLabel'
import { modifyActionParameter } from '../../../store/actions/actionsActions'
import { useDebouncedValue } from '../../../hooks/useActions/useDebouncedValue'

interface BoolACTNProps {
    data: any
    dataSchema: any
    name: string
    wrapper?: string
    dataPath: string[]
}

export const BoolACTN = (props: BoolACTNProps) => {
    const { data = {}, dataSchema, name, dataPath, wrapper } = props
    const { description, name: inputLabel, default: defaultValue } = dataSchema

    const dispatch = useAppDispatch()
    const [path] = useState([...(dataPath || []), name])

    const [inputState, setInputState, touched] = useFormValidation(
        data || {},
        {}
    )
    const debouncedInputState = useDebouncedValue(inputState, 500)

    const label = getInputLabel(name, inputLabel)

    const BoolInput = buildControlsExt(
        [
            checkboxInput(
                name,
                description,
                label,
                '',
                false,
                '',
                undefined,
                defaultValue
            ),
        ],
        inputState,
        setInputState,
        '',
        touched,
    )

    useEffect(() => {
        dispatch(
            modifyActionParameter({
                path,
                data: inputState,
            })
        )
    }, [debouncedInputState])

    return <div className={`${!wrapper ? 'w-100' : ''}`}>{BoolInput}</div>
}
