import React, { useEffect, useState } from 'react'
import { IDateTimeProps } from '../../models/DateTime/types'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { inputValidationCheck } from '../../../validators/inputValidators/inputValidators'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { datePickerErrors } from '../../../utils/validationErrors/datePickerErrors'
import { setTimeFormat } from '../../models/DateTime/utils'
import { buildControlsExt, dateTimePicker } from '../../inputs/controls'
import { useInitialEffect } from '../../../hooks/useInitialEffect'
import { setDefaultValue } from '../utils/setDefault'
import { isEmpty } from '../utils/isEmpty'
import {
    modifyActionParameter,
    setActionInvalid,
    setActionValid,
} from '../../../store/actions/actionsActions'
import { useDebouncedValue } from '../../../hooks/useActions/useDebouncedValue'
import { useEffectAfterMount } from '../../../hooks/useEffectAfterMount'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { selectInvalidActionByErrorKey } from '../selectors/invalidActionsSelectors'

export const DateTimeACTN = (props: IDateTimeProps) => {
    const { data = {}, dataSchema = {}, name, dataPath } = props
    const dispatch = useAppDispatch()

    const {
        default: defaultValue,
        format: timeSchema,
        is_required: isRequired,
        description,
        type,
    } = dataSchema

    const [path] = useState([...(dataPath || []), name])
    const validationCheck = inputValidationCheck(type, dataSchema)
    const [
        inputState,
        setInputState,
        touched,
        setTouched,
        validationResult,
        isInputStateValid,
    ] = useFormValidation(data, { [name]: validationCheck })

    const debouncedInputState = useDebouncedValue(inputState, 500)
    const isActionFieldInvalid = !!useTypedSelector(
        selectInvalidActionByErrorKey(path)
    )
    const isDataEmpty = isEmpty(data, name)
    const errMsg = datePickerErrors(inputState?.[name], dataSchema)
    const timeFormat = setTimeFormat(timeSchema)

    useInitialEffect(() => {
        if (isDataEmpty) {
            if (defaultValue) {
                setDefaultValue(setInputState, name, defaultValue)
            } else {
                setInputState((prev: any) => ({ ...prev, [name]: null }))
            }
        }
    })

    useEffect(() => {
        dispatch(
            modifyActionParameter({
                path,
                data: inputState,
            })
        )
    }, [debouncedInputState])

    useEffectAfterMount(() => {
        const isCurrentValueValid = isInputStateValid()
        if (isActionFieldInvalid === isCurrentValueValid) {
            if (isCurrentValueValid) {
                dispatch(setActionValid({ path }))
            } else {
                dispatch(setActionInvalid({ path }))
            }
        }
    }, [debouncedInputState])

    const DateTimeInput = buildControlsExt(
        [
            dateTimePicker(
                name,
                {
                    timeFormat,
                    timeSchema,
                    description,
                    isColumn: true,
                    errMsg,
                },
                isRequired
            ),
        ],
        inputState,
        setInputState,
        name,
        touched,
        setTouched,
        validationResult
    )

    return <div className="w-100">{DateTimeInput}</div>
}
