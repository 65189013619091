import { useCallback, useRef } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import styles from './ActionsBar.module.scss'
import { ActionTab } from './ActionTab/ActionTab'
import { ActionsHistory } from './ActionsHistory/ActionsHistory'
import { ActionUi } from './ActionUi/ActionUi'
import { selectProductCustomActions } from './selectors/actionsSelectors'
import { generateUniqueLocalId } from './utils/generateUniqueLocalId'
import { useLocalStorage } from '../../hooks/useLocalStorage'

export const ActionsBar = ({ guid }: { guid: string }) => {
    const productCustomActions =
        useTypedSelector(selectProductCustomActions(guid)) ?? {}
    const actionNamesRef = useRef(Object.keys(productCustomActions))
    const initialActiveAction = actionNamesRef.current[0] ?? ''
    const uniqueID = generateUniqueLocalId({
        path: [guid],
        name: initialActiveAction,
    })
    const [activeAction, setActiveActionName] = useLocalStorage(
        uniqueID,
        initialActiveAction
    )

    const handleActionTabClick = useCallback(
        (actionName: string) => setActiveActionName(actionName),
        []
    )

    return (
        <div className="d-flex">
            <div className={styles.actionsListContainer}>
                <h5 className={styles.headers}>Actions list</h5>
                <div className="d-flex flex-column gap-2 px-4 py-3">
                    {!!actionNamesRef.current.length &&
                        actionNamesRef.current.map((actionName) => (
                            <ActionTab
                                key={actionName}
                                guid={guid}
                                actionName={actionName}
                                activeActionName={activeAction}
                                onActionTabClick={handleActionTabClick}
                            />
                        ))}
                </div>
            </div>
            <div className={styles.customActionContainer}>
                <h5 className={styles.headers}>Custom action</h5>
                <div className="d-flex flex-column gap-3 px-4 py-3">
                    <ActionUi
                        key={`${guid}-${activeAction}`}
                        guid={guid}
                        actionName={activeAction}
                    />
                    <ActionsHistory guid={guid} />
                </div>
            </div>
        </div>
    )
}
